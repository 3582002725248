import classNames from "classnames";
import { PiMaskSad } from "react-icons/pi";

interface Props {
  guesses: string[];
  answer: string;
  wrongGuess: boolean;
  submitting: boolean;
  index: number;
  gameOver: boolean;
  answersRevealed: boolean;
}

const Guess: React.FC<Props> = ({
  guesses,
  answer,
  wrongGuess,
  submitting,
  index,
  answersRevealed,
  gameOver,
}) => {
  return (
    <div
      className={classNames(
        "rounded w-full text-center py-2 text-xl anton flex items-center justify-center min-h-12",
        {
          "bg-red-700 text-white text-2xl":
            (wrongGuess && !guesses.includes(answer)) ||
            (!guesses.includes(answer) && gameOver),
          "bg-spotify-green text-white":
            !wrongGuess && !guesses.includes(answer) && !gameOver,
          "bg-white text-spotify-green": guesses.includes(answer),
        }
      )}
    >
      {guesses.includes(answer) || answersRevealed ? answer : null}
      {!guesses.includes(answer) &&
      !submitting &&
      !wrongGuess &&
      !answersRevealed
        ? index + 1
        : null}
      {!guesses.includes(answer) && !submitting && wrongGuess ? (
        <PiMaskSad />
      ) : null}
      {submitting && !guesses.includes(answer) ? (
        <div className="lds-heart">
          <div></div>
        </div>
      ) : null}
    </div>
  );
};

export default Guess;
