import { useState, createContext, useMemo, useEffect } from "react";
import { uniqBy } from "lodash";
import { BsCassette, BsInfoCircle, BsClockHistory } from "react-icons/bs";
import { AutoComplete, Button, notification } from "antd";
import dayOfYear from "dayjs/plugin/dayOfYear";
import dayjs from "dayjs";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useWindowSize } from "@uidotdev/usehooks";
import Confetti from "react-confetti";

import { songData } from "./data/songData";
import fuzzysort from "fuzzysort";
import classNames from "classnames";
import { arrayShuffle } from "./utils/arrayShuffle";
import { useLocalStorage } from "./utils/useLocalStorage";
import Guess from "./components/Guess";
import GameLoseModal from "./components/GameLoseModal";
import GameWinModal from "./components/GameWinModal";
import GameInfoModal from "./components/GameInfoModal";
import GameHistoryModal from "./components/GameHistoryModal";

dayjs.extend(dayOfYear);

const Context = createContext({ name: "Default" });

const day = dayjs(new Date()).dayOfYear() - 169;
const data = songData[day];

function App() {
  const { width, height } = useWindowSize();
  const topFive = data.songs.slice(0, 5);
  const formattedDate = dayjs(new Date()).format("DD/MM/YYYY");
  const formattedBand = data.name.replace(/\s+/g, "");
  const contextValue = useMemo(() => ({ name: "Ant Design" }), []);
  const [api, contextHolder] = notification.useNotification();

  const [selectableOptions, setSelectableOptions] = useState(
    arrayShuffle(data.songs.map((value) => ({ value })))
  );
  const [filteredOptions, setFilteredOptions] = useState(selectableOptions);
  const [autoCompleteValue, setAutoCompleteValue] = useState("");
  const [, setCorrectGuesses] = useLocalStorage<string[]>(
    `spotifive_${formattedBand}_${formattedDate}_correctGuesses`,
    []
  );
  const [guesses, setGuesses] = useLocalStorage<string[]>(
    `spotifive_${formattedBand}_${formattedDate}_guesses`,
    []
  );
  const [lives, setLives] = useLocalStorage<number>(
    `spotifive_${formattedBand}_${formattedDate}_lives`,
    3
  );
  const [, setGameWon] = useLocalStorage<boolean>(
    `spotifive_${formattedBand}_${formattedDate}_winner`,
    false
  );
  const [submitting, setSubmitting] = useState(false);
  const [wrongGuess, setWrongGuess] = useState(false);
  const [modalOpen, setModalOpen] = useState(true);
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const [historyModalOpen, setHistoryModalOpen] = useState(false);
  const [answersRevealed, setAnswersRevealed] = useState(false);

  const correctGuesses = guesses.filter((guess) =>
    topFive.includes(guess)
  ).length;

  const GAME_OVER_WIN = correctGuesses === 5 && lives > 0;
  const GAME_OVER_LOSE = correctGuesses < 5 && lives === 0;

  const shareText = `
  SpotiFive 

  Can you name the most popular five songs by ${data.name}?

  ${topFive.map((s) => (guesses.includes(s) ? "🟩" : "🟥")).join(" ")}

  ${correctGuesses} / 5

  https://spotifive.craig-jones.co.uk

  #SpotiFive #${data.name.replace(/\s+/g, "")}
  `;

  const onSelect = (option: string) => {
    setSubmitting(true);
    setTimeout(() => {
      if (!topFive.includes(option)) {
        setLives((prev) => prev - 1);
        setWrongGuess(true);
        setTimeout(() => setWrongGuess(false), 1500);
      } else {
        setCorrectGuesses((prev) => [...prev, option]);
      }
      setSelectableOptions((prev) =>
        prev.filter((opt) => opt.value !== option)
      );
      setAutoCompleteValue("");
      setGuesses((prev) => [...prev, option]);

      setSubmitting(false);
    }, 3000);
  };

  const onSearch = (text: string) => {
    setAutoCompleteValue(text);
    setFilteredOptions(() =>
      fuzzysort
        .go(text, selectableOptions, { key: "value" })
        .map((option) => ({ value: option.target }))
    );
  };

  const openNotification = () => {
    api.info({
      message: `Results Copied to Clipboard!`,
      placement: "topLeft",
    });
  };

  useEffect(() => {
    if (GAME_OVER_WIN) {
      setGameWon(true);
    }
  }, [setGameWon, GAME_OVER_WIN]);

  return (
    <Context.Provider value={contextValue}>
      {contextHolder}
      <div className="bg-black">
        <div className="min-h-screen h-[690px] max-w-[540px] mx-auto flex flex-col gap-4 relative">
          <header className="text-white p-4 flex items-center flex-col gap-1 border-b border-gray-700 relative">
            <BsClockHistory
              onClick={() => setHistoryModalOpen((p) => !p)}
              className="text-white text-3xl cursor-pointer absolute top-2/4 -translate-y-1/2 left-4"
            />
            <h1 className="text-white anton text-4xl text-center">
              Spoti<span className="text-spotify-green">Five</span>
            </h1>
            <h2 className="montserrat text-center">Daily music quiz</h2>
            <BsInfoCircle
              onClick={() => setInfoModalOpen((p) => !p)}
              className="text-white text-3xl cursor-pointer absolute top-2/4 -translate-y-1/2 right-4"
            />
          </header>
          <main className="px-4 flex flex-col items-center gap-4">
            <div className="flex gap-1">
              <BsCassette
                className={classNames("text-spotify-green text-3xl", {
                  "opacity-25": lives < 1,
                })}
              />
              <BsCassette
                className={classNames("text-spotify-green text-3xl", {
                  "opacity-25": lives < 2,
                })}
              />
              <BsCassette
                className={classNames("text-spotify-green text-3xl", {
                  "opacity-25": lives < 3,
                })}
              />
            </div>
            <div className="w-full flex flex-col gap-1">
              {topFive.map((answer, i) => (
                <Guess
                  key={answer}
                  answer={answer}
                  index={i}
                  submitting={submitting}
                  wrongGuess={wrongGuess}
                  guesses={guesses}
                  gameOver={GAME_OVER_LOSE || GAME_OVER_WIN}
                  answersRevealed={answersRevealed}
                />
              ))}
            </div>

            <div className="w-full">
              <p className="text-white montserrat-bold mb-2">
                Can you name the most popular five songs by {data.name}?
              </p>
              <p className="text-white opacity-75 montserrat mb-4">
                Results are based on the 'popular' section on their Spotify
                page.
              </p>

              {GAME_OVER_LOSE && !answersRevealed ? (
                <Button
                  key="button"
                  type="primary"
                  danger
                  size="large"
                  className="w-full mb-4"
                  onClick={() => setAnswersRevealed(true)}
                >
                  Reveal Answers
                </Button>
              ) : null}

              {GAME_OVER_LOSE || GAME_OVER_WIN ? (
                <CopyToClipboard
                  text={shareText}
                  onCopy={() => openNotification()}
                >
                  <Button
                    key="button"
                    type="primary"
                    size="large"
                    className="w-full"
                  >
                    Share
                  </Button>
                </CopyToClipboard>
              ) : (
                <AutoComplete
                  className="!w-full"
                  options={uniqBy(filteredOptions, (opt) => opt.value)}
                  style={{ width: 200 }}
                  onSelect={onSelect}
                  onSearch={onSearch}
                  value={autoCompleteValue}
                  placeholder="Enter song here"
                  allowClear
                  defaultOpen={false}
                  open={autoCompleteValue.length >= 2}
                  disabled={submitting}
                  size="large"
                />
              )}
            </div>

            {GAME_OVER_LOSE ? (
              <GameLoseModal
                open={modalOpen}
                setOpenModal={setModalOpen}
                shareText={shareText}
                onCopy={() => openNotification()}
              />
            ) : null}

            {GAME_OVER_WIN ? (
              <GameWinModal
                open={modalOpen}
                setOpenModal={setModalOpen}
                shareText={shareText}
                onCopy={() => openNotification()}
              />
            ) : null}

            {infoModalOpen ? (
              <GameInfoModal
                open={infoModalOpen}
                setOpenModal={setInfoModalOpen}
              />
            ) : null}

            {historyModalOpen ? (
              <GameHistoryModal
                open={historyModalOpen}
                setOpenModal={setHistoryModalOpen}
              />
            ) : null}
          </main>
          <footer className="absolute w-full flex justify-center p-4 bottom-0 left-0 border-t border-gray-700">
            <a
              href="https://www.craig-jones.co.uk/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="w-12"
                src="/cj-logo.png"
                alt="Craig Jones Software Engineer"
              />
            </a>
          </footer>
        </div>
        {GAME_OVER_WIN ? (
          <Confetti width={width ?? undefined} height={height ?? undefined} />
        ) : null}
      </div>
    </Context.Provider>
  );
}

export default App;
