import { Button, Modal } from "antd";
import { CopyToClipboard } from "react-copy-to-clipboard";

interface Props {
  open: boolean;
  setOpenModal: (open: boolean) => void;
  shareText: string;
  onCopy: () => void;
}

const GameOverModal: React.FC<Props> = ({
  open,
  setOpenModal,
  shareText,
  onCopy,
}) => {
  return (
    <Modal
      open={open}
      title="Unlucky!"
      onOk={() => setOpenModal(false)}
      onCancel={() => setOpenModal(false)}
      footer={[
        <Button key="back" onClick={() => setOpenModal(false)}>
          Return
        </Button>,
        <CopyToClipboard text={shareText} onCopy={onCopy}>
          <Button
            key="button"
            type="primary"
            onClick={() => setOpenModal(false)}
          >
            Share
          </Button>
        </CopyToClipboard>,
      ]}
    >
      <p>You have been defeated by SpotiFive!</p>
      <p>Come back again tomorrow for another music challenge!</p>
    </Modal>
  );
};

export default GameOverModal;
