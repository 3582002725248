export const songData = [
  {
    name: "Blink-182",
    songs: [
      "All the Small Things",
      "What's My Age Again?",
      "First Date",
      "I Miss You",
      "Adam's Song",
      "Dammit",
      "The Rock Show",
      "Feeling This",
      "Stay Together for the Kids",
      "Josie",
      "Dumpweed",
      "Man Overboard",
      "Aliens Exist",
      "Carousel",
      "M+M's",
      "Anthem Part Two",
      "Down",
      "Always",
      "Not Now",
      "Wendy Clear",
      "Online Songs",
      "Every Time I Look for You",
      "Reckless Abandon",
      "Don't Leave Me",
      "Bored to Death",
    ],
  },
  {
    name: "The Offspring",
    songs: [
      "The Kids Aren't Alright",
      "You're Gonna Go Far, Kid",
      "Self Esteem",
      "Pretty Fly (For a White Guy)",
      "Why Don't You Get a Job?",
      "Come Out and Play",
      "Gone Away",
      "Original Prankster",
      "Want You Bad",
      "Hit That",
      "Gotta Get Away",
      "Hammerhead",
      "Days Go By",
      "Turning into You",
      "Kristy, Are You Doing Okay?",
      "Can't Repeat",
      "All I Want",
      "Defy You",
      "Spare Me the Details",
      "Staring at the Sun",
      "Million Miles Away",
      "Slim Pickens Does the Right Thing and Rides the Bomb to Hell",
      "Dirty Magic",
      "One Fine Day",
      "Genocide",
    ],
  },
  {
    name: "Sum 41",
    songs: [
      "Fat Lip",
      "In Too Deep",
      "Still Waiting",
      "The Hell Song",
      "Pieces",
      "Underclass Hero",
      "Walking Disaster",
      "With Me",
      "We're All to Blame",
      "Some Say",
      "Motivation",
      "Makes No Difference",
      "No Reason",
      "Over My Head (Better Off Dead)",
      "Pain for Pleasure",
      "Screaming Bloody Murder",
      "Fake My Own Death",
      "War",
      "Catch Me if You Can",
      "A Death in the Family",
      "45 (A Matter of Time)",
      "Never There",
      "Out for Blood",
      "Breaking the Chain",
      "There's No Solution",
    ],
  },
  {
    name: "Arctic Monkeys",
    songs: [
      "I Wanna Be Yours",
      "505",
      "Do I Wanna Know?",
      "Why'd You Only Call Me When You're High?",
      "R U Mine?",
      "I Bet You Look Good on the Dancefloor",
      "Arabella",
      "When the Sun Goes Down",
      "Crying Lightning",
      "Fluorescent Adolescent",
      "Brianstorm",
      "Cornerstone",
      "Snap Out of It",
      "One for the Road",
      "Knee Socks",
      "Suck It and See",
      "The Hellcat Spangled Shalalala",
      "Black Treacle",
      "Brick by Brick",
      "She's Thunderstorms",
      "Don't Sit Down 'Cause I've Moved Your Chair",
      "That's Where You're Wrong",
      "Piledriver Waltz",
      "Mad Sounds",
      "No. 1 Party Anthem",
      "Fire and the Thud",
    ],
  },
  {
    name: "My Chemical Romance",
    songs: [
      "Teenagers",
      "Welcome to the Black Parade",
      "Helena",
      "I'm Not Okay (I Promise)",
      "I Don't Love You",
      "Famous Last Words",
      "The Ghost of You",
      "Na Na Na (Na Na Na Na Na Na Na Na Na)",
      "Dead!",
      "Sing",
      "Disenchanted",
      "The Sharpest Lives",
      "This Is How I Disappear",
      "Cemetery Drive",
      "Thank You for the Venom",
      "You Know What They Do to Guys Like Us in Prison",
      "I Never Told You What I Do for a Living",
      "House of Wolves",
      "Cancer",
      "Mama",
      "Sleep",
      "Vampire Money",
      "The Only Hope for Me Is You",
      "The Kids from Yesterday",
      "Bulletproof Heart",
    ],
  },
  {
    name: "The Killers",
    songs: [
      "Mr. Brightside",
      "Somebody Told Me",
      "When You Were Young",
      "Human",
      "All These Things That I've Done",
      "Smile Like You Mean It",
      "Read My Mind",
      "Spaceman",
      "Runaways",
      "Bones",
      "Just Another Girl",
      "Shot at the Night",
      "Miss Atomic Bomb",
      "For Reasons Unknown",
      "Here with Me",
      "Battle Born",
      "Sam's Town",
      "This Is Your Life",
      "A Dustland Fairytale",
      "Be Still",
      "Run for Cover",
      "The Man",
      "Rut",
      "Wonderful Wonderful",
      "Out of My Mind",
    ],
  },
  {
    name: "Paramore",
    songs: [
      "Misery Business",
      "Still into You",
      "The Only Exception",
      "All I Wanted",
      "Decode",
      "Ain't It Fun",
      "crushcrushcrush",
      "That's What You Get",
      "Ignorance",
      "Brick by Boring Brick",
      "Pressure",
      "Emergency",
      "Playing God",
      "For a Pessimist, I'm Pretty Optimistic",
      "Let the Flames Begin",
      "When It Rains",
      "Careful",
      "Turn It Off",
      "Feeling Sorry",
      "Looking Up",
      "Where the Lines Overlap",
      "Conspiracy",
      "Whoa",
      "Fences",
      "Franklin",
    ],
  },
  {
    name: "Fall Out Boy",
    songs: [
      "Centuries",
      "Thnks fr th Mmrs",
      "Dance, Dance",
      "Sugar, We're Goin Down",
      "My Songs Know What You Did in the Dark (Light Em Up)",
      "I Don't Care",
      "This Ain't a Scene, It's an Arms Race",
      "Irresistible",
      "Uma Thurman",
      "The Phoenix",
      "Grand Theft Autumn/Where Is Your Boy",
      "A Little Less Sixteen Candles, a Little More Touch Me",
      "I'm Like a Lawyer with the Way I'm Always Trying to Get You Off (Me & You)",
      "Headfirst Slide into Cooperstown on a Bad Bet",
      "What a Catch, Donnie",
      "Tiffany Blews",
      "Disloyal Order of Water Buffaloes",
      "The (Shipped) Gold Standard",
      "The Take Over, the Breaks Over",
      "I Slept with Someone in Fall Out Boy and All I Got Was This Stupid Song Written About Me",
      "Get Busy Living or Get Busy Dying (Do Your Part to Save the Scene and Stop Going to Shows)",
      "Champagne for My Real Friends, Real Pain for My Sham Friends",
      "Nobody Puts Baby in the Corner",
      "Our Lawyer Made Us Change the Name of This Song So We Wouldn't Get Sued",
      "Sophomore Slump or Comeback of the Year",
    ],
  },
  {
    name: "Mumford & Sons",
    songs: [
      "Little Lion Man",
      "I Will Wait",
      "The Cave",
      "Believe",
      "Awake My Soul",
      "Guiding Light",
      "Babel",
      "Lover of the Light",
      "Hopeless Wanderer",
      "Roll Away Your Stone",
      "Ditmas",
      "There Will Be Time",
      "White Blank Page",
      "Tompkins Square Park",
      "Dust Bowl Dance",
      "Below My Feet",
      "Broad-Shouldered Beasts",
      "Just Smoke",
      "Wilder Mind",
      "Timshel",
      "Sigh No More",
      "Broken Crown",
      "Only Love",
      "After the Storm",
      "Where Are You Now",
    ],
  },
  {
    name: "Panic! at the Disco",
    songs: [
      "High Hopes",
      "House of Memories",
      "I Write Sins Not Tragedies",
      "ME!",
      "Lying Is the Most Fun a Girl Can Have Without Taking Her Clothes Off",
      "Death of a Bachelor",
      "This Is Gospel",
      "Victorious",
      "Emperor's New Clothes",
      "Girls/Girls/Boys",
      "The Ballad of Mona Lisa",
      "Nine in the Afternoon",
      "LA Devotee",
      "Say Amen (Saturday Night)",
      "Hallelujah",
      "Miss Jackson",
      "Northern Downpour",
      "But It's Better If You Do",
      "Nicotine",
      "Don't Threaten Me with a Good Time",
      "Ready to Go (Get Me Out of My Mind)",
      "Crazy=Genius",
      "New Perspective",
      "C'mon",
      "That Green Gentleman (Things Have Changed)",
      "The Only Difference Between Martyrdom and Suicide Is Press Coverage",
      "She's a Handsome Woman",
    ],
  },
  {
    name: "Good Charlotte",
    songs: [
      "The Anthem",
      "Lifestyles of the Rich & Famous",
      "Girls & Boys",
      "The River",
      "I Just Wanna Live",
      "Hold On",
      "Predictable",
      "Dance Floor Anthem",
      "Keep Your Hands Off My Girl",
      "The Chronicles of Life and Death",
      "We Believe",
      "Misery",
      "Little Things",
      "The Motivation Proclamation",
      "Broken Hearts Parade",
      "Victims of Love",
      "Where Would We Be Now",
      "A New Beginning",
      "The Young & the Hopeless",
      "The Story of My Old Man",
      "My Bloody Valentine",
      "Waldorfworldwide",
      "Say Anything",
      "S.O.S.",
      "It Wasn't Enough",
    ],
  },
  {
    name: "New Found Glory",
    songs: [
      "My Friends Over You",
      "Kiss Me",
      "All Downhill from Here",
      "Hit or Miss",
      "Iris",
      "Head on Collision",
      "Dressed to Kill",
      "Failure's Not Flattering",
      "Truth of My Youth",
      "Understatement",
      "Anthem for the Unwanted",
      "Don't Let Her Pull You Down",
      "Hold My Hand",
      "It's Not Your Fault",
      "Something I Call Personality",
      "This Disaster",
      "Forget My Name",
      "Sonny",
      "Never Give Up",
      "Truck Stop Blues",
      "Summer Fling, Don't Mean a Thing",
      "Sincerely Me",
      "Boy Crazy",
      "Better Off Dead",
      "I Don't Wanna Know",
      "The Story So Far",
      "On My Mind",
    ],
  },
  {
    name: "Jimmy Eat World",
    songs: [
      "The Middle",
      "Sweetness",
      "Bleed American",
      "Hear You Me",
      "Pain",
      "Work",
      "Big Casino",
      "Sure and Certain",
      "Get It Faster",
      "A Praise Chorus",
      "If You Don't, Don't",
      "Your House",
      "My Sundown",
      "Lucky Denver Mint",
      "Futures",
      "Polaris",
      "Always Be",
      "Let It Happen",
      "Blister",
      "For Me This Is Heaven",
      "Just Tonight...",
      "Dizzy",
      "23",
      "Cautioners",
      "Authority Song",
    ],
  },
  {
    name: "Foo Fighters",
    songs: [
      "Everlong",
      "The Pretender",
      "Learn to Fly",
      "Best of You",
      "My Hero",
      "All My Life",
      "Times Like These",
      "Monkey Wrench",
      "Walk",
      "Long Road to Ruin",
      "DOA",
      "Big Me",
      "Breakout",
      "Rope",
      "Arlandria",
      "Something from Nothing",
      "Wheels",
      "Run",
      "Congregation",
      "Bridge Burning",
      "These Days",
      "Let It Die",
      "Next Year",
      "Saint Cecilia",
      "White Limo",
    ],
  },
  {
    name: "MGMT",
    songs: [
      "Electric Feel",
      "Kids",
      "Little Dark Age",
      "Time to Pretend",
      "When You Die",
      "Me and Michael",
      "Flash Delirium",
      "Weekend Wars",
      "Congratulations",
      "The Youth",
      "Siberian Breaks",
      "It's Working",
      "She Works Out Too Much",
      "Alien Days",
      "Your Life Is a Lie",
      "James",
      "One Thing Left to Try",
      "Hand It Over",
      "In the Afternoon",
      "Of Moons, Birds & Monsters",
      "When You're Small",
      "I Found a Whistle",
      "Brian Eno",
      "Introspection",
      "Song for Dan Treacy",
    ],
  },
  {
    name: "Taylor Swift",
    songs: [
      "Fortnight",
      "Cruel Summer",
      "I Can Do It With a Broken Hear",
      "Down Bad",
      "Guilty as Sin?",
      "Love Story",
      "Shake It Off",
      "Blank Space",
      "You Belong with Me",
      "We Are Never Ever Getting Back Together",
      "I Knew You Were Trouble",
      "Bad Blood",
      "Look What You Made Me Do",
      "Style",
      "Wildest Dreams",
      "Delicate",
      "Me!",
      "You Need to Calm Down",
      "Cardigan",
      "Willow",
      "Ready for It?",
      "All Too Well",
      "22",
      "Red",
      "Fearless",
      "Mine",
      "Our Song",
      "Back to December",
      "Tim McGraw",
    ],
  },
  {
    name: "Nirvana",
    songs: [
      "Smells Like Teen Spirit",
      "Come As You Are",
      "About a Girl",
      "Heart-Shaped Box",
      "Lithium",
      "In Bloom",
      "All Apologies",
      "Rape Me",
      "Dumb",
      "The Man Who Sold the World",
      "Polly",
      "Breed",
      "Drain You",
      "Pennyroyal Tea",
      "Territorial Pissings",
      "On a Plain",
      "Serve the Servants",
      "Scentless Apprentice",
      "Something in the Way",
      "Where Did You Sleep Last Night",
      "Aneurysm",
      "Sliver",
      "Negative Creep",
      "Love Buzz",
      "Been a Son",
    ],
  },
  {
    name: "Prince",
    songs: [
      "Kiss",
      "Purple Rain",
      "When Doves Cry",
      "Raspberry Beret",
      "Let's Go Crazy",
      "1999",
      "Little Red Corvette",
      "I Would Die 4 U",
      "Sign 'O' the Times",
      "I Wanna Be Your Lover",
      "Cream",
      "Diamonds and Pearls",
      "Controversy",
      "Alphabet St.",
      "Delirious",
      "U Got the Look",
      "Sexy MF",
      "The Most Beautiful Girl in the World",
      "Adore",
      "If I Was Your Girlfriend",
      "Do Me, Baby",
      "Pop Life",
      "Take Me with U",
      "7",
      "Money Don't Matter 2 Night",
    ],
  },
  {
    name: "U2",
    songs: [
      "With or Without You",
      "I Still Haven't Found What I'm Looking For",
      "One",
      "Beautiful Day",
      "Sunday Bloody Sunday",
      "Where the Streets Have No Name",
      "Pride (In the Name of Love)",
      "Vertigo",
      "New Year's Day",
      "Elevation",
      "Mysterious Ways",
      "All I Want Is You",
      "Walk On",
      "Desire",
      "City of Blinding Lights",
      "Stuck in a Moment You Can't Get Out Of",
      "Sweetest Thing",
      "Even Better Than the Real Thing",
      "Hold Me, Thrill Me, Kiss Me, Kill Me",
      "The Unforgettable Fire",
      "Until the End of the World",
      "In a Little While",
      "The Fly",
      "Bad",
      "Ultra Violet (Light My Way)",
    ],
  },
  {
    name: "Elton John",
    songs: [
      "I'm Still Standing",
      "Rocket Man",
      "Don't Go Breaking My Heart",
      "Your Song",
      "Tiny Dancer",
      "Bennie and the Jets",
      "Goodbye Yellow Brick Road",
      "Candle in the Wind",
      "Don't Let the Sun Go Down on Me",
      "Crocodile Rock",
      "Saturday Night's Alright for Fighting",
      "Can You Feel the Love Tonight",
      "Daniel",
      "Someone Saved My Life Tonight",
      "Levon",
      "Philadelphia Freedom",
      "I Guess That's Why They Call It the Blues",
      "Sacrifice",
      "The Bitch Is Back",
      "Sorry Seems to Be the Hardest Word",
      "Circle of Life",
      "Honky Cat",
      "Blue Eyes",
      "Empty Garden",
      "Nikita",
    ],
  },
  {
    name: "Green Day",
    songs: [
      "Basket Case",
      "American Idiot",
      "Boulevard of Broken Dreams",
      "When I Come Around",
      "Good Riddance (Time of Your Life)",
      "Wake Me Up When September Ends",
      "21 Guns",
      "Holiday",
      "Longview",
      "Know Your Enemy",
      "Welcome to Paradise",
      "She",
      "Minority",
      "Hitchin' a Ride",
      "Brain Stew",
      "St. Jimmy",
      "Warning",
      "Nice Guys Finish Last",
      "Waiting",
      "Macy's Day Parade",
      "Geek Stink Breath",
      "Redundant",
      "King for a Day",
      "Walking Contradiction",
      "Jaded",
    ],
  },
  {
    name: "Death Cab for Cutie",
    songs: [
      "I Will Follow You into the Dark",
      "Soul Meets Body",
      "The Sound of Settling",
      "Title and Registration",
      "Transatlanticism",
      "Crooked Teeth",
      "Cath...",
      "Northern Lights",
      "No Room in Frame",
      "Black Sun",
      "Little Wanderer",
      "Gold Rush",
      "I Dreamt We Spoke Again",
      "Autumn Love",
      "You're a Tourist",
      "What Sarah Said",
      "The Ghosts of Beverly Drive",
      "Summer Skin",
      "Brothers on a Hotel Bed",
      "Grapevine Fires",
      "Expo '86",
      "Long Division",
      "Your Heart Is an Empty Room",
      "A Lack of Color",
      "Company Calls Epilogue",
    ],
  },
  {
    name: "Bruce Springsteen",
    songs: [
      "Dancing in the Dark",
      "Streets of Philadelphia",
      "I'm on Fire",
      "Born in the U.S.A.",
      "Hungry Heart",
      "Born to Run",
      "Thunder Road",
      "Glory Days",
      "The River",
      "Brilliant Disguise",
      "Tougher Than the Rest",
      "Atlantic City",
      "Human Touch",
      "Badlands",
      "My Hometown",
      "The Rising",
      "Secret Garden",
      "Prove It All Night",
      "Tenth Avenue Freeze-Out",
      "Jungleland",
      "Rosalita (Come Out Tonight)",
      "Cover Me",
      "One Step Up",
      "I'm Goin' Down",
      "Lonesome Day",
    ],
  },
  {
    name: "Eminem",
    songs: [
      "Without Me",
      "Mockingbird",
      "The Real Slim Shady",
      "Lose Yourself",
      "Stan",
      "Not Afraid",
      "Love the Way You Lie",
      "Rap God",
      "My Name Is",
      "Cleanin' Out My Closet",
      "Sing for the Moment",
      "The Way I Am",
      "When I'm Gone",
      "Till I Collapse",
      "Like Toy Soldiers",
      "Superman",
      "Just Lose It",
      "Beautiful",
      "Berzerk",
      "No Love",
      "Guilty Conscience",
      "Role Model",
      "White America",
      "We Made You",
      "Shake That",
    ],
  },
  {
    name: "Taking Back Sunday",
    songs: [
      "MakeDamnSure",
      "Cute Without the 'E' (Cut from the Team)",
      "A Decade Under the Influence",
      "You're So Last Summer",
      "Loved You a Little",
      "Liar (It Takes One to Know One)",
      "Set Phasers to Stun",
      "Timberwolves at New Jersey",
      "What's It Feel Like to Be a Ghost?",
      "Great Romances of the 20th Century",
      "Sink into Me",
      "One-Eighty by Summer",
      "This Photograph Is Proof (I Know You Know)",
      "Error: Operator",
      "Ghost Man on Third",
      "Spin",
      "You're No Exception",
      "Call Me in the Morning",
      "Flicker, Fade",
      "Better Homes and Gardens",
      "Tidal Wave",
      "El Paso",
      "Faith (When I Let You Down)",
      "Number Five with a Bullet",
      "Miami",
      "The Ballad of Sal Villanueva",
    ],
  },
  {
    name: "AC/DC",
    songs: [
      "Highway to Hell",
      "You Shook Me All Night Long",
      "Thunderstruck",
      "Back in Black",
      "T.N.T.",
      "Hells Bells",
      "Shoot to Thrill",
      "For Those About to Rock (We Salute You)",
      "Rock 'n' Roll Train",
      "Dirty Deeds Done Dirt Cheap",
      "It's a Long Way to the Top (If You Wanna Rock 'n' Roll)",
      "Whole Lotta Rosie",
      "Let There Be Rock",
      "The Jack",
      "Moneytalks",
      "Jailbreak",
      "Rock and Roll Ain't Noise Pollution",
      "If You Want Blood (You've Got It)",
      "Big Balls",
      "Who Made Who",
      "Sin City",
      "Shot in the Dark",
      "Touch Too Much",
      "Girls Got Rhythm",
      "Walk All Over You",
    ],
  },
  {
    name: "Simple Plan",
    songs: [
      "I'm Just a Kid",
      "Welcome to My Life",
      "Perfect",
      "Summer Paradise",
      "Jet Lag",
      "Shut Up!",
      "Addicted",
      "Untitled (How Could This Happen to Me?)",
      "I'd Do Anything",
      "Crazy",
      "When I'm Gone",
      "Jump",
      "Save You",
      "Your Love Is a Lie",
      "You Suck at Love",
      "Astronaut",
      "This Song Saved My Life",
      "Take My Hand",
      "Grow Up",
      "One Day",
      "Loser of the Year",
      "Generation",
      "Can't Keep My Hands Off You",
      "Holding On",
      "Outta My System",
    ],
  },
  {
    name: "Muse",
    songs: [
      "Supermassive Black Hole",
      "Uprising",
      "Starlight",
      "Hysteria",
      "Time Is Running Out",
      "Knights of Cydonia",
      "Madness",
      "Plug In Baby",
      "Psycho",
      "The 2nd Law: Isolated System",
      "Resistance",
      "Dead Inside",
      "Stockholm Syndrome",
      "Undisclosed Desires",
      "Bliss",
      "Map of the Problematique",
      "Reapers",
      "Follow Me",
      "Supremacy",
      "Panic Station",
      "Invincible",
      "Dig Down",
      "Mercy",
      "New Born",
      "Citizen Erased",
    ],
  },
  {
    name: "The Rolling Stones",
    songs: [
      "Paint It Black",
      "Start Me Up",
      "Satisfaction",
      "Beast of Burden",
      "Gimme Shelter",
      "Angie",
      "Sympathy for the Devil",
      "Brown Sugar",
      "Jumpin' Jack Flash",
      "Wild Horses",
      "Honky Tonk Women",
      "Under My Thumb",
      "Miss You",
      "Ruby Tuesday",
      "Street Fighting Man",
      "You Can't Always Get What You Want",
      "Let's Spend the Night Together",
      "Get Off of My Cloud",
      "Time Is on My Side",
      "Tumbling Dice",
      "It's Only Rock 'n Roll (But I Like It)",
      "Doo Doo Doo Doo Doo (Heartbreaker)",
      "Fool to Cry",
      "Shattered",
      "As Tears Go By",
    ],
  },
  {
    name: "The Strokes",
    songs: [
      "The Adults Are Talking",
      "Last Nite",
      "Reptilia",
      "Someday",
      "You Only Live Once",
      "Hard to Explain",
      "Under Control",
      "Juicebox",
      "12:51",
      "Is This It",
      "Machu Picchu",
      "New York City Cops",
      "Barely Legal",
      "Heart in a Cage",
      "Automatic Stop",
      "Razorblade",
      "Take It or Leave It",
      "The Modern Age",
      "Meet Me in the Bathroom",
      "Trying Your Luck",
      "The End Has No End",
      "What Ever Happened?",
      "Electricityscape",
      "Red Light",
      "Ask Me Anything",
      "Gratisfaction",
    ],
  },
  {
    name: "The xx",
    songs: [
      "Intro",
      "Crystalised",
      "Islands",
      "Angels",
      "VCR",
      "On Hold",
      "Basic Space",
      "Fiction",
      "Say Something Loving",
      "I Dare You",
      "Chained",
      "Shelter",
      "Dangerous",
      "Heart Skipped a Beat",
      "Night Time",
      "Stars",
      "Infinity",
      "Sunset",
      "Test Me",
      "Lips",
      "Performance",
      "Reunion",
      "Swept Away",
      "Unfold",
      "Brave for You",
    ],
  },
  {
    name: "Michael Jackson",
    songs: [
      "Billie Jean",
      "Beat It",
      "Smooth Criminal",
      "Rock with You",
      "Thriller",
      "Bad",
      "Black or White",
      "Man in the Mirror",
      "The Way You Make Me Feel",
      "Don't Stop 'Til You Get Enough",
      "Human Nature",
      "Dirty Diana",
      "Heal the World",
      "Remember the Time",
      "They Don't Care About Us",
      "You Are Not Alone",
      "Off the Wall",
      "Will You Be There",
      "Wanna Be Startin' Somethin'",
      "Leave Me Alone",
      "P.Y.T. (Pretty Young Thing)",
      "She's Out of My Life",
      "Give In to Me",
      "Dangerous",
      "In the Closet",
    ],
  },
  {
    name: "Vampire Weekend",
    songs: [
      "A-Punk",
      "Campus",
      "Capricorn",
      "Oxford Comma",
      "Prep-School Gangsters",
      "Diane Young",
      "Hannah Hunt",
      "Cape Cod Kwassa Kwassa",
      "Cousins",
      "Step",
      "Unbelievers",
      "Harmony Hall",
      "Giving Up the Gun",
      "Ya Hey",
      "Holiday",
      "Mansard Roof",
      "White Sky",
      "California English",
      "Run",
      "Finger Back",
      "Everlasting Arms",
      "Don't Lie",
      "Obvious Bicycle",
      "Taxi Cab",
      "Walcott",
      "The Kids Don't Stand a Chance",
      "Bryn",
    ],
  },
  {
    name: "Lady Gaga",
    songs: [
      "Poker Face",
      "Shallow",
      "Bad Romance",
      "Just Dance",
      "Always Remember Us This Way",
      "Born This Way",
      "The Edge of Glory",
      "Paparazzi",
      "Million Reasons",
      "Applause",
      "Telephone",
      "Alejandro",
      "Do What U Want",
      "Judas",
      "LoveGame",
      "You and I",
      "Stupid Love",
      "G.U.Y.",
      "Marry the Night",
      "Monster",
      "Speechless",
      "Dance in the Dark",
      "Beautiful, Dirty, Rich",
      "Eh, Eh (Nothing Else I Can Say)",
      "Perfect Illusion",
    ],
  },
  {
    name: "Linkin Park",
    songs: [
      "In the End",
      "Numb",
      "Faint",
      "One Step Closer",
      "Crawling",
      "Somewhere I Belong",
      "What I've Done",
      "Breaking the Habit",
      "Papercut",
      "Bleed It Out",
      "Shadow of the Day",
      "Leave Out All the Rest",
      "The Catalyst",
      "Burn It Down",
      "Waiting for the End",
      "Lost in the Echo",
      "Castle of Glass",
      "New Divide",
      "Given Up",
      "Guilty All the Same",
      "Talking to Myself",
      "Heavy",
      "Until It's Gone",
      "Wretches and Kings",
      "Battle Symphony",
    ],
  },
  {
    name: "Ed Sheeran",
    songs: [
      "Perfect",
      "Shape of You",
      "Photograph",
      "Shivers",
      "Thinking Out Loud",
      "Perfect",
      "Castle on the Hill",
      "Galway Girl",
      "I See Fire",
      "The A Team",
      "Lego House",
      "Don't",
      "Sing",
      "Happier",
      "Give Me Love",
      "Dive",
      "How Would You Feel (Paean)",
      "Supermarket Flowers",
      "One",
      "You Need Me, I Don't Need You",
      "All of the Stars",
      "Bloodstream",
      "Tenerife Sea",
      "Kiss Me",
      "Drunk",
      "Small Bump",
      "Eraser",
    ],
  },
  {
    name: "Coldplay",
    songs: [
      "Yellow",
      "Something Just Like This",
      "Viva La Vida",
      "A Sky Full of Stars",
      "The Scientist",
      "Fix You",
      "Clocks",
      "Paradise",
      "Shiver",
      "Speed of Sound",
      "In My Place",
      "Magic",
      "Adventure of a Lifetime",
      "Trouble",
      "Every Teardrop Is a Waterfall",
      "Hymn for the Weekend",
      "Charlie Brown",
      "Talk",
      "Don't Panic",
      "Life in Technicolor II",
      "Princess of China",
      "Atlas",
      "Orphans",
      "Everglow",
      "Midnight",
    ],
  },
  {
    name: "Rihanna",
    songs: [
      "Umbrella",
      "Diamonds",
      "We Found Love",
      "Disturbia",
      "S&M",
      "Only Girl (In the World)",
      "Stay",
      "Rude Boy",
      "What's My Name?",
      "Don't Stop the Music",
      "Take a Bow",
      "Where Have You Been",
      "Pon de Replay",
      "SOS",
      "Unfaithful",
      "Rehab",
      "Hard",
      "Russian Roulette",
      "Shut Up and Drive",
      "Hate That I Love You",
      "Te Amo",
      "Rockstar 101",
      "Needed Me",
      "Kiss It Better",
      "Bitch Better Have My Money",
    ],
  },
  {
    name: "Pink Floyd",
    songs: [
      "Another Brick in the Wall, Part 2",
      "Wish You Were Here",
      "Comfortably Numb",
      "Breathe",
      "Money",
      "Time",
      "Shine On You Crazy Diamond",
      "Hey You",
      "Us and Them",
      "Brain Damage",
      "Eclipse",
      "Run Like Hell",
      "Dogs",
      "Pigs",
      "Mother",
      "Young Lust",
      "One of These Days",
      "Have a Cigar",
      "Fearless",
      "The Great Gig in the Sky",
      "Echoes",
      "High Hopes",
      "Learning to Fly",
      "On the Turning Away",
      "See Emily Play",
    ],
  },
  {
    name: "Jay-Z",
    songs: [
      "Empire State of Mind",
      "Ni**as in Paris",
      "Numb / Encore",
      "Run This Town",
      "No Church in the Wild",
      "99 Problems",
      "Dirt Off Your Shoulder",
      "Hard Knock Life (Ghetto Anthem)",
      "Izzo (H.O.V.A.)",
      "Big Pimpin'",
      "Holy Grail",
      "Otis",
      "Public Service Announcement (Interlude)",
      "Young Forever",
      "Can I Get A...",
      "Excuse Me Miss",
      "Change Clothes",
      "On to the Next One",
      "The Story of O.J.",
      "Jigga What, Jigga Who",
      "Girls, Girls, Girls",
      "Ain't No Nigga",
      "Feelin' It",
      "Dead Presidents II",
      "Politics as Usual",
    ],
  },
  {
    name: "Katy Perry",
    songs: [
      "Last Friday Night (T.G.I.F.)",
      "Teenage Dream",
      "Dark Horse",
      "Hot N Cold",
      "California Gurls",
      "Firework",
      "Roar",
      "I Kissed a Girl",
      "E.T.",
      "Wide Awake",
      "The One That Got Away",
      "Part of Me",
      "Unconditionally",
      "Waking Up in Vegas",
      "This Is How We Do",
      "Birthday",
      "Swish Swish",
      "Chained to the Rhythm",
      "Bon Appétit",
      "Rise",
      "Never Really Over",
      "Small Talk",
      "Harleys in Hawaii",
      "Cozy Little Christmas",
      "Smile",
    ],
  },
  {
    name: "Brand New",
    songs: [
      "The Quiet Things That No One Ever Knows",
      "Jesus",
      "Sic Transit Gloria... Glory Fades",
      "Seventy Times 7",
      "The Boy Who Blocked His Own Shot",
      "Degausser",
      "Okay I Believe You, but My Tommy Gun Don't",
      "I Will Play My Game Beneath the Spin Light",
      "Jude Law and a Semester Abroad",
      "Mix Tape",
      "Sowing Season",
      "Guernica",
      "You Won't Know",
      "Limousine",
      "Archers",
      "Soco Amaretto Lime",
      "Play Crack the Sky",
      "Sink",
      "At the Bottom",
      "Gasoline",
      "Millstone",
      "Vices",
      "Bought a Bride",
      "Daisy",
      "Noro",
    ],
  },
  {
    name: "Madonna",
    songs: [
      "Popular",
      "La Isla Bonita",
      "Hung Up",
      "Material Girl",
      "Like a Prayer",
      "Vogue",
      "Like a Virgin",
      "Into the Groove",
      "Holiday",
      "Papa Don't Preach",
      "Express Yourself",
      "Ray of Light",
      "Frozen",
      "Borderline",
      "Lucky Star",
      "Open Your Heart",
      "Live to Tell",
      "Crazy for You",
      "Dress You Up",
      "True Blue",
      "Justify My Love",
      "Beautiful Stranger",
      "Music",
      "Deeper and Deeper",
      "Cherish",
      "Secret",
    ],
  },
  {
    name: "Red Hot Chili Peppers",
    songs: [
      "Californication",
      "Scar Tissue",
      "Can't Stop",
      "Under the Bridge",
      "Otherside",
      "Give It Away",
      "By the Way",
      "Snow (Hey Oh)",
      "Dani California",
      "Soul to Squeeze",
      "Parallel Universe",
      "Around the World",
      "Tell Me Baby",
      "The Zephyr Song",
      "Breaking the Girl",
      "Suck My Kiss",
      "Fortune Faded",
      "Higher Ground",
      "Universally Speaking",
      "Road Trippin'",
      "Aeroplane",
      "Dark Necessities",
      "The Adventures of Rain Dance Maggie",
      "Goodbye Angels",
      "Look Around",
    ],
  },
  {
    name: "Maroon 5",
    songs: [
      "Maps",
      "Payphone",
      "Moves Like Jagger",
      "This Love",
      "She Will Be Loved",
      "Sugar",
      "Girls Like You",
      "Memories",
      "One More Night",
      "Animals",
      "Harder to Breathe",
      "Makes Me Wonder",
      "Don't Wanna Know",
      "Cold",
      "Love Somebody",
      "Wait",
      "Daylight",
      "Misery",
      "Give a Little More",
      "If I Never See Your Face Again",
      "Wake Up Call",
      "Lucky Strike",
      "Beautiful Goodbye",
      "Goodnight Goodnight",
      "Sunday Morning",
    ],
  },
  {
    name: "Yellowcard",
    songs: [
      "Ocean Avenue",
      "Only One",
      "Breathing",
      "Lights and Sounds",
      "Way Away",
      "Hang You Up",
      "For You, and Your Denial",
      "Believe",
      "Light Up the Sky",
      "Rough Landing, Holly",
      "Always Summer",
      "Awakening",
      "With You Around",
      "Life of a Salesman",
      "Twenty Three",
      "Keeper",
      "Back Home",
      "Gifts and Curses",
      "Fighting",
      "Here I Am Alive",
      "Southern Air",
      "Surface of the Sun",
      "Ten",
      "Sureshot",
      "Hollywood Died",
    ],
  },
  {
    name: "The All-American Rejects",
    songs: [
      "Dirty Little Secret",
      "Gives You Hell",
      "Move Along",
      "Swing, Swing",
      "It Ends Tonight",
      "My Paper Heart",
      "The Last Song",
      "I Wanna",
      "Night Drive",
      "Top of the World",
      "One More Sad Song",
      "Change Your Mind",
      "Stab My Back",
      "Why Worry",
      "Dance Inside",
      "Don't Leave Me",
      "Real World",
      "Kids in the Street",
      "Heartbeat Slowing Down",
      "Beekeeper's Daughter",
      "Walk Over Me",
      "Affection",
      "Someday's Gone",
      "Bleed into Your Mind",
      "The Wind Blows",
    ],
  },
  {
    name: "Queen",
    songs: [
      "Don't Stop Me Now",
      "Another One Bites the Dust",
      "Bohemian Rhapsody",
      "Crazy Little Thing Called Love",
      "Under Pressure",
      "We Will Rock You",
      "We Are the Champions",
      "Somebody to Love",
      "Radio Ga Ga",
      "I Want to Break Free",
      "Killer Queen",
      "A Kind of Magic",
      "Who Wants to Live Forever",
      "Love of My Life",
      "You're My Best Friend",
      "Seven Seas of Rhye",
      "The Show Must Go On",
      "Innuendo",
      "Flash",
      "Hammer to Fall",
      "I Want It All",
      "Friends Will Be Friends",
      "Play the Game",
      "These Are the Days of Our Lives",
      "One Vision",
    ],
  },
  {
    name: "Foals",
    songs: [
      "My Number",
      "Mountain at My Gates",
      "Late Night",
      "Spanish Sahara",
      "Birch Tree",
      "Inhaler",
      "What Went Down",
      "Bad Habit",
      "Balloons",
      "Olympic Airways",
      "Total Life Forever",
      "The Runner",
      "On the Luna",
      "Exits",
      "Sunday",
      "Red Socks Pugie",
      "Black Gold",
      "Providence",
      "Give It All",
      "Out of the Woods",
      "White Onions",
      "Syrups",
      "Neptune",
      "Snake Oil",
      "London Thunder",
    ],
  },
  {
    name: "Arcade Fire",
    songs: [
      "The Suburbs",
      "Wake Up",
      "Rebellion (Lies)",
      "Everything Now",
      "Ready to Start",
      "Sprawl II (Mountains Beyond Mountains)",
      "No Cars Go",
      "Afterlife",
      "Reflektor",
      "We Exist",
      "Keep the Car Running",
      "Neighborhood #1 (Tunnels)",
      "Creature Comfort",
      "My Body Is a Cage",
      "Neighborhood #3 (Power Out)",
      "Here Comes the Night Time",
      "Black Mirror",
      "Modern Man",
      "Haiti",
      "Intervention",
      "Normal Person",
      "Windowsill",
      "Ocean of Noise",
      "We Don't Deserve Love",
      "Crown of Love",
    ],
  },
  {
    name: "Twenty One Pilots",
    songs: [
      "Stressed Out",
      "Ride",
      "Heathens",
      "Car Radio",
      "Tear in My Heart",
      "Holding on to You",
      "Jumpsuit",
      "My Blood",
      "Chlorine",
      "Nico and the Niners",
      "Smithereens",
      "Levitate",
      "Guns for Hands",
      "Lane Boy",
      "Fairly Local",
      "The Judge",
      "Polarize",
      "Doubt",
      "We Don't Believe What's on TV",
      "Hometown",
      "Not Today",
      "Goner",
      "Addict with a Pen",
      "Ode to Sleep",
      "Fall Away",
    ],
  },
  {
    name: "Led Zeppelin",
    songs: [
      "Stairway to Heaven",
      "Immigrant Song",
      "Whole Lotta Love",
      "Black Dog",
      "Rock and Roll",
      "Kashmir",
      "Dazed and Confused",
      "Ramble On",
      "Over the Hills and Far Away",
      "When the Levee Breaks",
      "Communication Breakdown",
      "The Ocean",
      "Heartbreaker",
      "Since I've Been Loving You",
      "Good Times Bad Times",
      "Babe I'm Gonna Leave You",
      "The Rain Song",
      "Going to California",
      "Misty Mountain Hop",
      "No Quarter",
      "Trampled Under Foot",
      "Fool in the Rain",
      "Houses of the Holy",
      "In My Time of Dying",
      "Thank You",
    ],
  },
  {
    name: "Beyoncé",
    songs: [
      "Crazy in Love",
      "Halo",
      "Single Ladies (Put a Ring on It)",
      "Drunk in Love",
      "Irreplaceable",
      "Love on Top",
      "Formation",
      "Run the World (Girls)",
      "If I Were a Boy",
      "XO",
      "Sweet Dreams",
      "Best Thing I Never Had",
      "Partition",
      "7/11",
      "Flawless",
      "Sorry",
      "Hold Up",
      "Déjà Vu",
      "Upgrade U",
      "Beautiful Liar",
      "Naughty Girl",
      "Check On It",
      "Diva",
      "Ego",
      "Haunted",
    ],
  },
  {
    name: "Kanye West",
    songs: [
      "CARNIVAL",
      "Heartless",
      "Flashing Lights",
      "Stronger",
      "Gold Digger",
      "Power",
      "Runaway",
      "All of the Lights",
      "Jesus Walks",
      "Love Lockdown",
      "Can't Tell Me Nothing",
      "Good Life",
      "Touch the Sky",
      "Bound 2",
      "Homecoming",
      "Mercy",
      "Fade",
      "Wolves",
      "Black Skinhead",
      "I Am a God",
      "Monster",
      "Famous",
      "Blood on the Leaves",
      "No Church in the Wild",
      "Ultralight Beam",
      "New Slaves",
    ],
  },
  {
    name: "The Used",
    songs: [
      "The Taste of Ink",
      "Body Bag",
      "Buried Myself Alive",
      "Pretty Handsome Awkward",
      "All That I've Got",
      "Blue and Yellow",
      "I Caught Fire",
      "Take It Away",
      "Blood on My Hands",
      "A Box Full of Sharp Objects",
      "Listening",
      "Empty with You",
      "Cry",
      "Liar Liar (Burn in Hell)",
      "Bird and the Worm",
      "The Bird and the Worm",
      "The Ripper",
      "The Taste of Ink",
      "On My Own",
      "Maybe Memories",
      "Poetic Tragedy",
      "Say Days Ago",
      "A Song to Stifle Imperial Progression (A Work in Progress)",
      "Smother Me",
      "Earthquake",
      "Light with a Sharpened Edge",
    ],
  },
  {
    name: "The Beatles",
    songs: [
      "Here Comes the Sun",
      "Twist and Shout",
      "Yesterday",
      "Let It Be",
      "Come Together",
      "Hey Jude",
      "Help!",
      "A Day in the Life",
      "Something",
      "Lucy in the Sky with Diamonds",
      "Penny Lane",
      "Strawberry Fields Forever",
      "All You Need Is Love",
      "Can't Buy Me Love",
      "Yellow Submarine",
      "Eleanor Rigby",
      "Ticket to Ride",
      "Blackbird",
      "I Want to Hold Your Hand",
      "Eight Days a Week",
      "She Loves You",
      "Paperback Writer",
      "Norwegian Wood",
      "While My Guitar Gently Weeps",
      "Lady Madonna",
    ],
  },
  {
    name: "Adele",
    songs: [
      "Set Fire to the Rain",
      "Easy On Me",
      "Someone Like You",
      "Rolling in the Deep",
      "Skyfall",
      "Hello",
      "When We Were Young",
      "Send My Love (To Your New Lover)",
      "Hometown Glory",
      "Chasing Pavements",
      "Make You Feel My Love",
      "Rumour Has It",
      "Turning Tables",
      "All I Ask",
      "Water Under the Bridge",
      "Million Years Ago",
      "Daydreamer",
      "Don't You Remember",
      "Love in the Dark",
      "One and Only",
      "Take It All",
      "I Miss You",
      "Sweetest Devotion",
      "Cold Shoulder",
      "Melt My Heart to Stone",
      "Tired",
    ],
  },
  {
    name: "Two Door Cinema Club",
    songs: [
      "What You Know",
      "Undercover Martyn",
      "Something Good Can Work",
      "Sun",
      "I Can Talk",
      "Sleep Alone",
      "Are We Ready? (Wreck)",
      "Next Year",
      "Changing of the Seasons",
      "Cigarettes in the Theatre",
      "Come Back Home",
      "Handshake",
      "Do You Want It All?",
      "Eat That Up, It's Good for You",
      "Settle",
      "This Is the Life",
      "Pyramid",
      "Someday",
      "Bad Decisions",
      "Gameshow",
      "Lavender",
      "Je Viens De La",
      "Satellite",
      "Satisfaction Guaranteed",
      "Once",
    ],
  },
  {
    name: "Radiohead",
    songs: [
      "Creep",
      "No Surprises",
      "Karma Police",
      "High and Dry",
      "Jigsaw Falling into Place",
      "Paranoid Android",
      "Fake Plastic Trees",
      "Street Spirit (Fade Out)",
      "Everything in Its Right Place",
      "Idioteque",
      "Pyramid Song",
      "There There",
      "Reckoner",
      "Weird Fishes/Arpeggi",
      "All I Need",
      "Just",
      "Exit Music (For a Film)",
      "Lucky",
      "The National Anthem",
      "Knives Out",
      "My Iron Lung",
      "Lotus Flower",
      "2 + 2 = 5",
      "You and Whose Army?",
      "Let Down",
      "Optimistic",
      "Morning Bell",
      "Airbag",
      "Climbing Up the Walls",
      "The Bends",
      "I Might Be Wrong",
      "Bullet Proof..I Wish I Was",
      "Motion Picture Soundtrack",
      "House of Cards",
      "Planet Telex",
      "Life in a Glasshouse",
      "Bodysnatchers",
      "Black Star",
      "Sit Down. Stand Up.",
      "Electioneering",
    ],
  },
  {
    name: "Whitney Houston",
    songs: [
      "I Wanna Dance with Somebody (Who Loves Me)",
      "Higher Love",
      "I Will Always Love You",
      "I Have Nothing",
      "How Will I Know",
      "I'm Every Woman",
      "Greatest Love of All",
      "Saving All My Love for You",
      "My Love Is Your Love",
      "So Emotional",
      "All the Man That I Need",
      "Didn't We Almost Have It All",
      "Exhale (Shoop Shoop)",
      "Where Do Broken Hearts Go",
      "One Moment in Time",
      "It's Not Right but It's Okay",
      "I'm Your Baby Tonight",
      "Heartbreak Hotel",
      "You Give Good Love",
      "Run to You",
      "Count on Me",
      "Million Dollar Bill",
      "Step by Step",
      "I Look to You",
      "You Were Loved",
      "My Name Is Not Susan",
      "I Believe in You and Me",
      "Try It on My Own",
      "Fine",
      "Whatchulookinat",
      "Love Will Save the Day",
      "Hold Me",
      "I Learned from the Best",
      "If I Told You That",
      "Thinking About You",
      "One of Those Days",
      "Just the Lonely Talking Again",
      "Love That Man",
      "For the Lovers",
      "Like I Never Left",
      "Queen of the Night",
      "Why Does It Hurt So Bad",
      "Joy",
      "Get It Back",
      "Who Do You Love",
      "Someone for Me",
      "Until You Come Back",
      "Take Good Care of My Heart",
      "Lover for Life",
      "Who Would Imagine a King",
      "Where You Are",
    ],
  },
  {
    name: "Depeche Mode",
    songs: [
      "Enjoy the Silence",
      "Just Can't Get Enough",
      "Personal Jesus",
      "Precious",
      "People Are People",
      "Never Let Me Down Again",
      "Everything Counts",
      "Strangelove",
      "Policy of Truth",
      "World in My Eyes",
      "Master and Servant",
      "Blasphemous Rumours",
      "A Question of Time",
      "A Question of Lust",
      "Black Celebration",
      "Behind the Wheel",
      "Shake the Disease",
      "It's No Good",
      "Walking in My Shoes",
      "I Feel You",
      "Dream On",
      "Suffer Well",
      "Barrel of a Gun",
      "See You",
      "New Life",
      "Useless",
      "Martyr",
      "Freelove",
      "Halo",
      "In Your Room",
      "John the Revelator",
      "Everything to Gain",
      "Leave in Silence",
      "Get the Balance Right",
      "Nothing",
      "The Meaning of Love",
      "But Not Tonight",
      "Stripped",
      "The Things You Said",
      "Fly on the Windscreen",
    ],
  },
  {
    name: "Mumford & Sons",
    songs: [
      "Little Lion Man",
      "I Will Wait",
      "The Cave",
      "Believe",
      "Awake My Soul",
      "Lover of the Light",
      "Babel",
      "Hopeless Wanderer",
      "Roll Away Your Stone",
      "Ditmas",
      "The Wolf",
      "Guiding Light",
      "Timshel",
      "Whispers in the Dark",
      "Below My Feet",
      "Wilder Mind",
      "White Blank Page",
      "Dust Bowl Dance",
      "There Will Be Time",
      "After the Storm",
      "Lover's Eyes",
      "Broken Crown",
      "Tompkins Square Park",
      "Snake Eyes",
      "Just Smoke",
      "Ghosts That We Knew",
      "Only Love",
      "Believe (Live)",
      "Forever",
      "Rose of Sharon",
      "Woman",
      "If I Say",
      "42",
      "Slip Away",
      "Picture You",
      "Darkness Visible",
      "Delta",
      "The Wild",
      "October Skies",
      "Guiding Light (Acoustic)",
      "Not with Haste",
      "Sigh No More",
      "Home",
      "Winter Winds",
      "Reminder",
      "Hold on to What You Believe",
      "Feel the Tide",
      "Friend of the Devil",
      "The Banjolin Song",
      "Unfinished Business",
      "Hurt",
    ],
  },
  {
    name: "Kelly Clarkson",
    songs: [
      "Since U Been Gone",
      "Stronger (What Doesn't Kill You)",
      "Because of You",
      "Breakaway",
      "My Life Would Suck Without You",
      "Behind These Hazel Eyes",
      "A Moment Like This",
      "Already Gone",
      "Miss Independent",
      "Catch My Breath",
      "Walk Away",
      "Heartbeat Song",
      "People Like Us",
      "Mr. Know It All",
      "Dark Side",
      "Invincible",
      "Piece by Piece",
      "Tie It Up",
      "Sober",
      "I Do Not Hook Up",
      "Never Again",
      "Don't Waste Your Time",
      "Cry",
      "I Forgive You",
      "Don't Rush",
      "Stronger (What Doesn't Kill You) (Remix)",
      "Second Wind",
      "You Can't Win",
      "Thankful",
      "Breakaway (Acoustic)",
      "The Trouble with Love Is",
      "Low",
      "All I Ever Wanted",
      "If I Can't Have You",
      "Impossible",
      "Ready",
      "How I Feel",
      "Don't Let Me Stop You",
      "Long Shot",
      "Save You",
      "Why Don't You Try",
      "Let Me Down",
      "Hello",
      "The Sun Will Rise",
      "Whyyawannabringmedown",
      "Einstein",
      "War Is Over",
      "If No One Will Listen",
      "You Love Me",
      "Run Run Run",
    ],
  },
  {
    name: "Echo & the Bunnymen",
    songs: [
      "The Killing Moon",
      "Lips Like Sugar",
      "Bring on the Dancing Horses",
      "The Cutter",
      "Seven Seas",
      "Ocean Rain",
      "Rescue",
      "Bedbugs and Ballyhoo",
      "People Are Strange",
      "Silver",
      "Over the Wall",
      "Never Stop",
      "Do It Clean",
      "A Promise",
      "Villiers Terrace",
      "Back of Love",
      "All My Colours",
      "Going Up",
      "Nocturnal Me",
      "All That Jazz",
      "With a Hip",
      "The Game",
      "My Kingdom",
      "Heads Will Roll",
      "Stormy Weather",
      "Think I Need It Too",
      "Nothing Lasts Forever",
      "Rust",
      "Scissors in the Sand",
      "Porcupine",
      "Flowers",
      "The Back of Love",
      "Lost and Found",
      "Angels and Devils",
      "Monkeys",
      "Pride",
      "Simple Stuff",
      "The Yo Yo Man",
      "Fuel",
      "Gods Will Be Gods",
      "The Disease",
      "Pencil Skirt",
      "Hole in the Holy",
      "Morning Sun",
      "Of a Life",
      "The Idolness of Gods",
      "Start Again",
      "Frozen Tears",
      "Crystal Days",
      "Heaven Up Here",
    ],
  },
  {
    name: "R.E.M.",
    songs: [
      "Losing My Religion",
      "Everybody Hurts",
      "Shiny Happy People",
      "It's the End of the World as We Know It (And I Feel Fine)",
      "Man on the Moon",
      "The One I Love",
      "Nightswimming",
      "Orange Crush",
      "Stand",
      "Imitation of Life",
      "Drive",
      "What's the Frequency, Kenneth?",
      "Electrolite",
      "E-Bow the Letter",
      "Daysleeper",
      "Leaving New York",
      "At My Most Beautiful",
      "Bang and Blame",
      "Near Wild Heaven",
      "Uberlin",
      "Radio Free Europe",
      "Supernatural Superserious",
      "Lotus",
      "Strange Currencies",
      "Crush with Eyeliner",
      "Bittersweet Me",
      "Bad Day",
      "So. Central Rain",
      "Fall on Me",
      "Begin the Begin",
      "Talk About the Passion",
      "Pop Song 89",
      "Can't Get There from Here",
      "Finest Worksong",
      "Drive",
      "How the West Was Won and Where It Got Us",
      "Walk Unafraid",
      "All the Way to Reno (You're Gonna Be a Star)",
      "I've Been High",
      "Mine Smell Like Honey",
    ],
  },
  {
    name: "Weezer",
    songs: [
      "Island in the Sun",
      "Say It Ain't So",
      "Buddy Holly",
      "Beverly Hills",
      "Undone - The Sweater Song",
      "Hash Pipe",
      "Perfect Situation",
      "Pork and Beans",
      "El Scorcho",
      "Photograph",
      "Troublemaker",
      "My Name Is Jonas",
      "Keep Fishin'",
      "Dope Nose",
      "If You're Wondering If I Want You To (I Want You To)",
      "Back to the Shack",
      "Thank God for Girls",
      "We Are All on Drugs",
      "Cleopatra",
      "Go Away",
      "California Kids",
      "King of the World",
      "L.A. Girlz",
      "In the Garage",
      "The Good Life",
      "Pink Triangle",
      "Tired of Sex",
      "Butterfly",
      "Getchoo",
      "Holiday",
      "I Just Threw Out the Love of My Dreams",
      "No Other One",
      "The World Has Turned and Left Me Here",
      "Susanne",
      "Dreamin'",
      "Troublemaker",
      "I'm Your Daddy",
      "The British Are Coming",
      "Da Vinci",
      "Jacked Up",
    ],
  },
  {
    name: "Genesis",
    songs: [
      "Invisible Touch",
      "That's All",
      "I Can't Dance",
      "Land of Confusion",
      "Follow You Follow Me",
      "Turn It on Again",
      "No Son of Mine",
      "Mama",
      "Tonight, Tonight, Tonight",
      "In Too Deep",
      "Jesus He Knows Me",
      "Abacab",
      "Misunderstanding",
      "Throwing It All Away",
      "Firth of Fifth",
      "The Carpet Crawlers",
      "Home by the Sea",
      "The Lamb Lies Down on Broadway",
      "Duchess",
      "Dance on a Volcano",
      "Entangled",
      "Ripples",
      "Squonk",
      "Los Endos",
      "Eleventh Earl of Mar",
      "Your Own Special Way",
      "Blood on the Rooftops",
      "All in a Mouse's Night",
      "Burning Rope",
      "Deep in the Motherlode",
      "Say It's Alright Joe",
      "The Lady Lies",
      "The Knife",
      "Dancing with the Moonlit Knight",
      "The Musical Box",
      "Watcher of the Skies",
      "Get 'Em Out by Friday",
      "Suppers Ready",
      "I Know What I Like (In Your Wardrobe)",
      "Fountain of Salmacis",
      "Fly on a Windshield",
      "In the Cage",
      "The Colony of Slippermen",
      "A Trick of the Tail",
      "Robbery, Assault and Battery",
      "It",
      "Counting Out Time",
      "Back in N.Y.C.",
      "Afterglow",
      "Me and Sarah Jane",
    ],
  },
  {
    name: "Dr. Dre",
    songs: [
      "Still D.R.E.",
      "Forgot About Dre",
      "The Next Episode",
      "Nuthin' but a 'G' Thang",
      "What's the Difference",
      "Let Me Ride",
      "Keep Their Heads Ringin'",
      "Xxplosive",
      "Dre Day",
      "Lil' Ghetto Boy",
      "The Watcher",
      "Deeez Nuuuts",
      "The Message",
      "F*** You",
      "Natural Born Killaz",
      "Big Ego's",
      "B**** N****z",
      "The Day the N****z Took Over",
      "Bang Bang",
      "Some L.A. N****z",
      "Talk About It",
      "Animals",
      "It's All on Me",
      "All in a Day's Work",
      "Loose Cannons",
      "Issues",
      "Deep Water",
      "One Shot One Kill",
      "For the Love of Money",
      "Satisfaction",
      "Gone",
      "Medicine Man",
      "Darkside/Gone",
      "The Plan",
      "Set It Off",
      "Compton",
      "Genocide",
      "Just Another Day",
      "Love Hater",
      "Bigger Business",
      "Sh***in' on Me",
      "Trauma",
      "Hey Young World",
      "Old Times Sake",
      "Chevy Ridin' High",
      "Come and Get Me",
      "I Get It In",
      "P.I.M.P. (Remix)",
      "X",
      "Last Dayz",
    ],
  },
  {
    name: "INXS",
    songs: [
      "Need You Tonight",
      "Never Tear Us Apart",
      "New Sensation",
      "Beautiful Girl",
      "Mystify",
      "Suicide Blonde",
      "Devil Inside",
      "By My Side",
      "Original Sin",
      "Disappear",
      "Don't Change",
      "What You Need",
      "Listen Like Thieves",
      "Kick",
      "Bitter Tears",
      "Burn for You",
      "The One Thing",
      "Not Enough Time",
      "Elegantly Wasted",
      "Just Keep Walking",
      "Kiss the Dirt (Falling Down the Mountain)",
      "Baby Don't Cry",
      "Shining Star",
      "Guns in the Sky",
      "Good Times",
      "This Time",
      "Heaven Sent",
      "The Gift",
      "By My Side",
      "Afterglow",
      "Faith in Each Other",
      "To Look at You",
      "Taste It",
      "Disappear",
      "Spy of Love",
      "We Are Thrown Together",
      "Biting Bullets",
      "Dancing on the Jetty",
      "Stay Young",
      "To Look at You",
      "The Swing",
      "The Loved One",
      "Don't Lose Your Head",
      "Men and Women",
      "Hear That Sound",
      "Old World New World",
      "The Stairs",
      "On My Way",
      "The Strangest Party (These Are the Times)",
    ],
  },
  {
    name: "A-ha",
    songs: [
      "Take on Me",
      "Hunting High and Low",
      "The Sun Always Shines on T.V.",
      "Stay on These Roads",
      "Crying in the Rain",
      "The Living Daylights",
      "You Are the One",
      "Touchy!",
      "I've Been Losing You",
      "The Blood That Moves the Body",
      "Summer Moved On",
      "Foot of the Mountain",
      "Velvet",
      "Train of Thought",
      "Manhattan Skyline",
      "Scoundrel Days",
      "Minor Earth Major Sky",
      "Forever Not Yours",
      "Move to Memphis",
      "Dark Is the Night for All",
      "The Swing of Things",
      "There's Never a Forever Thing",
      "Here I Stand and Face the Rain",
      "Lifelines",
      "Early Morning",
      "Sycamore Leaves",
      "Shapes That Go Together",
      "I Call Your Name",
      "Analogue (All I Want)",
      "The Bandstand",
      "Cast in Steel",
      "Cold River",
      "East of the Sun",
      "Mother Nature Goes to Heaven",
      "Stay on These Roads (Live)",
      "Soft Rains of April",
      "You Are the One (Remix)",
      "The Blue Sky",
      "Angel in the Snow",
      "Time & Again",
      "Nothing Is Keeping You Here",
      "Living a Boy's Adventure Tale",
      "I Dream Myself Alive",
      "Out of Blue Comes Green",
      "Butterfly, Butterfly (The Last Hurrah)",
      "Shadowside",
      "To Let You Win",
      "Start the Simulator",
      "Cold as Stone",
      "Move to Memphis (Live)",
    ],
  },
  {
    name: "The Specials",
    songs: [
      "A Message to You, Rudy",
      "Ghost Town",
      "Gangsters",
      "Too Much Too Young",
      "Monkey Man",
      "Rat Race",
      "Do Nothing",
      "Stereotype",
      "Nelson Mandela",
      "Nite Klub",
      "Concrete Jungle",
      "Doesn't Make It Alright",
      "Too Hot",
      "Friday Night, Saturday Morning",
      "Why?",
      "Pearl's Cafe",
      "Blank Expression",
      "Little Bitch",
      "Stupid Marriage",
      "Running Away",
      "It's Up to You",
      "Rude Boys Outa Jail",
      "International Jet Set",
      "Hey, Little Rich Girl",
      "I Can't Stand It",
      "Man at C&A",
      "War Crimes",
      "Rude Boy's Life",
      "Enjoy Yourself (It's Later Than You Think)",
      "You're Wondering Now",
      "Do the Dog",
      "Guns of Navarone",
      "Pressure Drop",
      "Dawning of a New Era",
      "Longshot Kick de Bucket",
      "Enjoy Yourself (Reprise)",
      "Jezebel",
      "Sock It to 'Em J.B.",
      "Maggie's Farm",
      "Breaking Point",
      "Take Five",
      "What I Like Most About You Is Your Girlfriend",
      "Can't Get a Break",
      "Rude Boy Gone to Jail",
      "Running Away (Extended Version)",
      "Maggie's Farm (Live)",
      "Why? (Extended Version)",
      "Sock It to 'Em J.B. (Live)",
      "Concrete Jungle (Live)",
    ],
  },
  {
    name: "Oasis",
    songs: [
      "Wonderwall",
      "Don't Look Back in Anger",
      "Stop Crying Your Heart Out",
      "Champagne Supernova",
      "Live Forever",
      "Supersonic",
      "Some Might Say",
      "Cigarettes & Alcohol",
      "Whatever",
      "Morning Glory",
      "The Masterplan",
      "Roll With It",
      "D'You Know What I Mean?",
      "Stand by Me",
      "Rock 'n' Roll Star",
      "Shakermaker",
      "All Around the World",
      "Go Let It Out",
      "Little by Little",
      "Songbird",
      "She's Electric",
      "Acquiesce",
      "The Shock of the Lightning",
      "Slide Away",
      "Columbia",
      "Half the World Away",
      "Cast No Shadow",
      "Don't Go Away",
      "Fade Away",
      "Hey Now!",
      "The Hindu Times",
      "I'm Outta Time",
      "Let There Be Love",
      "Digsy's Dinner",
      "Turn Up the Sun",
      "Gas Panic!",
      "Keep the Dream Alive",
      "Bag It Up",
      "The Importance of Being Idle",
      "Little James",
    ],
  },
  {
    name: "Madness",
    songs: [
      "Our House",
      "It Must Be Love",
      "One Step Beyond",
      "Baggy Trousers",
      "House of Fun",
      "My Girl",
      "Night Boat to Cairo",
      "Embarrassment",
      "Wings of a Dove",
      "The Prince",
      "Michael Caine",
      "Shut Up",
      "The Sun and the Rain",
      "Grey Day",
      "Driving in My Car",
      "Tomorrow's (Just Another Day)",
      "Cardiac Arrest",
      "Uncle Sam",
      "Sweetest Girl",
      "The Harder They Come",
      "Bed and Breakfast Man",
      "Madness",
      "Disappear",
      "In the City",
      "Calling Cards",
      "Victoria Gardens",
      "Lovestruck",
      "Drip Fed Fred",
      "One Better Day",
      "Return of the Los Palmas 7",
      "The Communicator",
      "Keep Moving",
      "The Ghost Train",
      "Yesterday's Men",
      "Uncle Sam (Ray Gun Mix)",
      'One Step Beyond (7" Version)',
      "The Young and the Old",
      "Deceives the Eye",
      "March of the Gherkins",
      "Please Don't Go",
      "Shadow on the House",
      "The National Pastime",
      "Tarzan's Nuts",
      "Sign of the Times",
      "Day on the Town",
      "Memories",
      "I'll Compete",
      "Dust Devil",
      "In the Rain",
      "Bingo",
    ],
  },
  {
    name: "Boyz II Men",
    songs: [
      "On Bended Knee",
      "End of the Road",
      "I'll Make Love to You",
      "One Sweet Day",
      "Motownphilly",
      "Water Runs Dry",
      "It's So Hard to Say Goodbye to Yesterday",
      "A Song for Mama",
      "4 Seasons of Loneliness",
      "I Can Love You Like That",
      "Pass You By",
      "Thank You",
      "Doin' Just Fine",
      "Uhh Ahh",
      "In the Still of the Nite (I'll Remember)",
      "Hey Lover",
      "Please Don't Go",
      "So Amazing",
      "Yesterday",
      "I Will Get There",
      "I Finally Know",
      "Can You Stand the Rain",
      "Relax Your Mind",
      "Dear God",
      "Girl in the Life Magazine",
      "I Sit Away",
      "Jezzebel",
      "The Aaliyah Song",
      "Just My Imagination (Running Away with Me)",
      "Never",
      "I Do",
      "Can't Let Her Go",
      "Thank You in Advance",
      "Dreams",
      "What the Deal",
      "Come On",
      "Your Home Is in My Heart (Stella's Love Theme)",
      "The Color of Love",
      "Vibin'",
      "Better Half",
      "Brokenhearted",
      "Rose and a Honeycomb",
      "Will",
      "Cold December Nights",
      "All Around the World",
      "Sympin'",
      "What Child Is This?",
      "Ribbon in the Sky",
      "I Miss You",
      "Baby C'mon",
      "Everyday It Rains",
    ],
  },
  {
    name: "The Police",
    songs: [
      "Every Breath You Take",
      "Roxanne",
      "Message in a Bottle",
      "Every Little Thing She Does Is Magic",
      "Don't Stand So Close to Me",
      "King of Pain",
      "Walking on the Moon",
      "Wrapped Around Your Finger",
      "De Do Do Do, De Da Da Da",
      "Can't Stand Losing You",
      "So Lonely",
      "Synchronicity II",
      "Invisible Sun",
      "Spirits in the Material World",
      "Driven to Tears",
      "Truth Hits Everybody",
      "Bring on the Night",
      "Next to You",
      "When the World Is Running Down, You Make the Best of What's Still Around",
      "The Bed's Too Big Without You",
      "Tea in the Sahara",
      "Born in the 50's",
      "Murder by Numbers",
      "Demolition Man",
      "Walking in Your Footsteps",
      "Rehumanize Yourself",
      "Shadows in the Rain",
      "One World (Not Three)",
      "Man in a Suitcase",
      "Omegaman",
      "Canary in a Coalmine",
      "Miss Gradenko",
      "It's Alright for You",
      "On Any Other Day",
      "Contact",
      "Does Everyone Stare",
      "No Time This Time",
      "Synchronicity I",
      "Voices Inside My Head",
      "Hole in My Life",
      "Darkness",
      "Deathwish",
      "Behind My Camel",
      "Reggatta de Blanc",
      "Landlord",
      "The Other Way of Stopping",
      "Peanuts",
      "Too Much Information",
      "Visions of the Night",
      "Friends",
    ],
  },
  {
    name: "The Kooks",
    songs: [
      "Naïve",
      "She Moves in Her Own Way",
      "Seaside",
      "Ooh La",
      "Always Where I Need to Be",
      "Sofa Song",
      "Junk of the Heart (Happy)",
      "Shine On",
      "Sway",
      "See the Sun",
      "Is It Me",
      "Eskimo Kiss",
      "Do You Wanna",
      "Eddie's Gun",
      "Down",
      "Matchbox",
      "Bad Habit",
      "Forgive & Forget",
      "Around Town",
      "Sweet Emotion",
      "No Pressure",
      "Got Your Number",
      "Fractured and Dazed",
      "Be Who You Are",
      "Tick of Time",
      "She Moves in Her Own Way (Acoustic)",
      "Seaside (Demo)",
      "Pull Me In",
      "Weight of the World",
      "Love It All",
      "All Over Town",
      "Are We Electric",
      "Backstabber",
      "Dying to Live",
      "Funk 50",
      "If Only",
      "Kids",
      "Little Red House",
      "Look What You've Done",
      "Magnet",
      "Pamela",
      "Runaway",
      "See the World",
      "Stormy Weather",
      "Time Awaits",
      "Watching the Ships Roll In",
      "When the Night Comes",
      "You Don't Love Me",
      "Young Folks",
      "You Don't Love Me (Live)",
    ],
  },
  {
    name: "Bloc Party",
    songs: [
      "Banquet",
      "Helicopter",
      "This Modern Love",
      "Flux",
      "So Here We Are",
      "Like Eating Glass",
      "Hunting for Witches",
      "I Still Remember",
      "Pioneers",
      "Positive Tension",
      "Two More Years",
      "Price of Gas",
      "Mercury",
      "One More Chance",
      "Waiting for the 7.18",
      "The Prayer",
      "She's Hearing Voices",
      "Kreuzberg",
      "Blue Light",
      "Octopus",
      "Ratchet",
      "V.A.L.I.S.",
      "Hymns",
      "Truth",
      "Signs",
      "The Love Within",
      "Ares",
      "Uniform",
      "Song for Clay (Disappear Here)",
      "Biko",
      "Real Talk",
      "Compliments",
      "On",
      "Coliseum",
      "Tulips",
      "Luno",
      "Ion Square",
      "Exes",
      "Team A",
      "Banquet (Phones Disco Edit)",
      "Little Thoughts",
      "Skeleton",
      "This Is Not a Competition",
      "SRXT",
      "Little Wrench",
      "England",
      "Letter to My Son",
      "Eden",
      "3x3",
      "Hunting for Witches (Fury666 Remix)",
    ],
  },
  {
    name: "Ice Cube",
    songs: [
      "It Was a Good Day",
      "You Know How We Do It",
      "Check Yo Self",
      "No Vaseline",
      "You Can Do It",
      "Dead Homiez",
      "Friday",
      "Gangsta Rap Made Me Do It",
      "Bop Gun (One Nation)",
      "What Can I Do?",
      "Ghetto Bird",
      "Why We Thugs",
      "Pushin' Weight",
      "Smoke Some Weed",
      "Steady Mobbin'",
      "My Summer Vacation",
      "The Nigga Ya Love to Hate",
      "AmeriKKKa's Most Wanted",
      "Wicked",
      "A Bird in the Hand",
      "True to the Game",
      "Who's the Mack?",
      "Really Doe",
      "We Be Clubbin'",
      "Givin' Up the Nappy Dug Out",
      "Jackin' for Beats",
      "Once Upon a Time in the Projects",
      "What They Hittin' Foe?",
      "I Wanna Kill Sam",
      "A Gangsta's Fairytale",
      "It Takes a Nation",
      "Color Blind",
      "Get Off My Dick and Tell Yo Bitch to Come Here",
      "Look Who's Burnin'",
      "I'm Only Out for One Thang",
      "When I Get to Heaven",
      "When Will They Shoot?",
      "Enemy",
      "Endangered Species (Tales from the Darkside)",
      "Us",
      "Bird in the Hand",
      "Say Hi to the Bad Guy",
      "Alive on Arrival",
      "Man's Best Friend",
      "The Product",
      "Roll All Day",
      "Greed",
      "Down for Whatever",
      "I Rep That West",
    ],
  },
  {
    name: "The Human League",
    songs: [
      "Don't You Want Me",
      "Human",
      "Fascination",
      "Together in Electric Dreams",
      "Mirror Man",
      "Love Action (I Believe in Love)",
      "The Lebanon",
      "Tell Me When",
      "Open Your Heart",
      "Being Boiled",
      "Louise",
      "Empire State Human",
      "Heart Like a Wheel",
      "(Keep Feeling) Fascination",
      "Life on Your Own",
      "Soundtrack to a Generation",
      "The Sound of the Crowd",
      "I'm Coming Back",
      "Night People",
      "Rock Me Again and Again and Again and Again and Again and Again (Six Times)",
      "Stay with Me Tonight",
      "Love Is All That Matters",
      "Seconds",
      "Thirteen",
      "These Are the Days",
      "Kiss the Future",
      "The Things That Dreams Are Made Of",
      "Blind Youth",
      "Do or Die",
      "Love on the Run",
      "Men Are Dreamers",
      "Get It Right This Time",
      "Hard Times",
      "Boys and Girls",
      "The Path of Least Resistance",
      "Only After Dark",
      "WXJL Tonight",
      "I Need Your Loving",
      "Zero as a Limit",
      "I Am the Law",
      "Dreams of Leaving",
      "Marianne",
      "Crow and a Baby",
      "Life Kills",
      "Rebound",
      "Sin City",
      "Single Minded",
      "Sky",
      "Tell Me When (Remix)",
      "The Sound of the Crowd (Remix)",
    ],
  },
  {
    name: "Duran Duran",
    songs: [
      "Ordinary World",
      "Hungry Like the Wolf",
      "Come Undone",
      "Rio",
      "Girls on Film",
      "Save a Prayer",
      "The Reflex",
      "A View to a Kill",
      "Notorious",
      "Is There Something I Should Know?",
      "Union of the Snake",
      "Planet Earth",
      "Wild Boys",
      "The Wild Boys",
      "New Moon on Monday",
      "All She Wants Is",
      "I Don't Want Your Love",
      "Electric Barbarella",
      "Serious",
      "Out of My Mind",
      "Skin Trade",
      "Too Much Information",
      "Meet El Presidente",
      "My Own Way",
      "Careless Memories",
      "Perfect Day",
      "Falling Down",
      "Burning the Ground",
      "Violence of Summer (Love's Taking Over)",
      "Do You Believe in Shame?",
      "Big Thing",
      "None of the Above",
      "All You Need Is Now",
      "What Happens Tomorrow",
      "Someone Else Not Me",
      "Sunrise",
      "Nite-Runner",
      "Tempted",
      "The Valley",
      "Last Day on Earth",
    ],
  },
  {
    name: "Soft Cell",
    songs: [
      "Tainted Love",
      "Where Did Our Love Go",
      "Say Hello, Wave Goodbye",
      "Sex Dwarf",
      "Torch",
      "Bedsitter",
      "Numbers",
      "Loving You, Hating Me",
      "Memorabilia",
      "Entertain Me",
      "What?",
      "It's a Mug's Game",
      "Barriers",
      "Seedy Films",
      "Heat",
      "Baby Doll",
      "Surrender to a Stranger",
      "Youth",
      "Chips on My Shoulder",
      "Frustration",
      "Facility Girls",
      "Insecure Me",
      "Secret Life",
      "Soul Inside",
      "Forever the Same",
      "Meet Murder My Angel",
      "Divided Soul",
      "Slave to This",
      "Where the Heart Is",
      'Numbers (12" Version)',
      "Little Rough Rhinestone",
      "Down in the Subway",
      "Tainted Love (2XS Remix)",
      "Where Was Your Heart (When You Needed It Most)",
      "Tainted Dub",
      "Say Hello, Wave Goodbye (Extended Version)",
      "Disease and Desire",
      "Caligula Syndrome",
      "A Man Could Get Lost",
      "Bleak Is My Favourite Cliché",
      "Her Imagination",
      "Martin",
      "Fun City",
      "L'Esqualita",
      'Facility Girls (12" Version)',
      'Divided Soul (12" Version)',
      "Youth (Extended Version)",
      "Soul Inside (Extended Version)",
      "Where the Heart Is (Extended Version)",
    ],
  },
  {
    name: "Dire Straits",
    songs: [
      "Sultans of Swing",
      "Walk of Life",
      "Money for Nothing",
      "Brothers in Arms",
      "Romeo and Juliet",
      "Tunnel of Love",
      "Private Investigations",
      "So Far Away",
      "Your Latest Trick",
      "Telegraph Road",
      "Lady Writer",
      "Industrial Disease",
      "Down to the Waterline",
      "Where Do You Think You're Going?",
      "Twisting by the Pool",
      "On Every Street",
      "Why Worry",
      "The Bug",
      "Heavy Fuel",
      "Love over Gold",
      "Expresso Love",
      "Once upon a Time in the West",
      "It Never Rains",
      "Single-Handed Sailor",
      "Southbound Again",
      "Hand in Hand",
      "Skateaway",
      "News",
      "Solid Rock",
      "When It Comes to You",
      "Iron Hand",
      "Fade to Black",
      "Water of Love",
      "In the Gallery",
      "Wild West End",
      "Lions",
      "Angel of Mercy",
      "Communiqué",
      "Lady Writer (Live)",
      "Portobello Belle",
      "One World",
      "Brothers in Arms (Live)",
      "Calling Elvis",
      "Setting Me Up",
      "Six Blade Knife",
      "Follow Me Home",
      "Ride Across the River",
      "Two Young Lovers",
      "Ticket to Heaven",
      "Planet of New Orleans",
    ],
  },
  {
    name: "Mariah Carey",
    songs: [
      "Obsessed",
      "We Belong Together",
      "Fantasy",
      "Always Be My Baby",
      "Hero",
      "One Sweet Day",
      "Vision of Love",
      "Emotions",
      "Without You",
      "Dreamlover",
      "Honey",
      "My All",
      "Heartbreaker",
      "Touch My Body",
      "Shake It Off",
      "Love Takes Time",
      "Make It Happen",
      "I Don't Wanna Cry",
      "Someday",
      "Can't Let Go",
      "Breakdown",
      "Thank God I Found You",
      "I'll Be There",
      "I Still Believe",
      "Butterfly",
      "Through the Rain",
      "Don't Forget About Us",
      "I Want to Know What Love Is",
      "Can't Take That Away (Mariah's Theme)",
      "Against All Odds (Take a Look at Me Now)",
      "Open Arms",
      "All I Want for Christmas Is You",
      "If It's Over",
      "Anytime You Need a Friend",
      "The Roof",
      "Fly Like a Bird",
      "Whenever You Call",
      "Forever",
      "Underneath the Stars",
      "Looking In",
      "After Tonight",
      "Your Girl",
      "Don't Stop (Funkin' 4 Jamaica)",
      "Boy (I Need You)",
      "Bringin' on the Heartbreak",
      "Loverboy",
      "Get Your Number",
      "Shake It Off (Remix)",
      "Obsessed (Remix)",
    ],
  },
  {
    name: "New Order",
    songs: [
      "Blue Monday",
      "Bizarre Love Triangle",
      "Age of Consent",
      "True Faith",
      "Regret",
      "Temptation",
      "Ceremony",
      "The Perfect Kiss",
      "Round & Round",
      "Shellshock",
      "Thieves Like Us",
      "Crystal",
      "Sub-culture",
      "World in Motion",
      "Confusion",
      "Touched by the Hand of God",
      "Ruined in a Day",
      "Fine Time",
      "Dreams Never End",
      "Love Vigilantes",
      "Everything's Gone Green",
      "Vanishing Point",
      "Krafty",
      "1963",
      "Someone Like You",
      "Mr. Disco",
      "Lonesome Tonight",
      "Run",
      "Face Up",
      "We All Stand",
      "Mesh",
      "State of the Nation",
      "Every Little Counts",
      "Leave Me Alone",
      "All Day Long",
      "Vicious Streak",
      "Who's Joe?",
      "Jetstream",
      "Turn",
      "Guilt Is a Useless Emotion",
      "Rock the Shack",
      "Working Overtime",
      "Plastic",
      "Tutti Frutti",
      "People on the High Line",
      "Restless",
      "Singularity",
      "Academic",
      "Nothing but a Fool",
      "Unlearn This Hatred",
    ],
  },
  {
    name: "Snoop Dogg",
    songs: [
      "Young, Wild & Free",
      "Nuthin' but a 'G' Thang",
      "Drop It Like It's Hot",
      "Gin and Juice",
      "2 of Americaz Most Wanted",
      "Who Am I (What's My Name)?",
      "Beautiful",
      "Still D.R.E.",
      "Murder Was the Case",
      "Doggy Dogg World",
      "Sensual Seduction",
      "Ain't No Fun (If the Homies Can't Have None)",
      "Lodi Dodi",
      "B**** Please",
      "Vato",
      "Tha Shiznit",
      "Pump Pump",
      "Let's Get Blown",
      "Lay Low",
      "Gangsta Luv",
      "Boss' Life",
      "Signs",
      "I Wanna Rock",
      "Ups & Downs",
      "My Medicine",
      "Wet",
      "P.I.M.P. (Remix)",
      "Life of da Party",
      "Imagine",
      "Stacey Adams",
      "Candy (Drippin' Like Water)",
      "Upside Down",
      "From tha Chuuuch to da Palace",
      "Oh No",
      "Neva Left",
      "That's That",
      "Get a Light",
      "Boom",
      "Deep Cover",
      "Santa Claus Goes Straight to the Ghetto",
      "B**** Please II",
      "Signs (Remix)",
      "Drop It Like It's Hot (Remix)",
      "Murder Was the Case (Remix)",
      "Doggy Dogg World (Remix)",
      "Sensual Seduction (Remix)",
      "Lay Low (Remix)",
      "Pump Pump (Remix)",
      "Let's Get Blown (Remix)",
      "Boss' Life (Remix)",
      "Signs (Acoustic)",
    ],
  },
  {
    name: "Eurythmics",
    songs: [
      "Sweet Dreams (Are Made of This)",
      "Here Comes the Rain Again",
      "There Must Be an Angel (Playing with My Heart)",
      "Thorn in My Side",
      "The Miracle of Love",
      "Would I Lie to You?",
      "Love Is a Stranger",
      "Missionary Man",
      "When Tomorrow Comes",
      "Who's That Girl?",
      "Sexcrime (Nineteen Eighty-Four)",
      "It's Alright (Baby's Coming Back)",
      "Sisters Are Doin' It for Themselves",
      "Don't Ask Me Why",
      "Right by Your Side",
      "I Need a Man",
      "Angel",
      "Beethoven (I Love to Listen To)",
      "You Have Placed a Chill in My Heart",
      "King and Queen of America",
      "I've Got a Life",
      "I Saved the World Today",
      "Revival",
      "Shame",
      "Belinda",
      "This City Never Sleeps",
      "Jennifer",
      "Never Gonna Cry Again",
      "The Walk",
      "Love Is a Stranger (Coldcut Remix)",
      "When the Day Goes Down",
      "Regrets",
      "Aqua",
      "Let's Just Close Our Eyes",
      "English Summer",
      "I Remember You",
      "Greetings from a Dead Man",
      "Take Your Pain Away",
      "Ministry of Love",
      "Conditioned Soul",
      "Wide Eyed Girl",
      "4/4 in Leather",
      "Farewell to Tawathie",
      "The King and Queen of America",
      "The First Cut",
      "I Need You",
      "I Did It Just the Same",
      "Jealousy",
      "I've Tried Everything",
      "Step on the Beast",
    ],
  },
  {
    name: "Guns N' Roses",
    songs: [
      "Sweet Child o' Mine",
      "Welcome to the Jungle",
      "Paradise City",
      "November Rain",
      "Knockin' on Heaven's Door",
      "Don't Cry",
      "Patience",
      "Live and Let Die",
      "You Could Be Mine",
      "Estranged",
      "Nightrain",
      "Civil War",
      "Mr. Brownstone",
      "Used to Love Her",
      "Yesterdays",
      "My Michelle",
      "It's So Easy",
      "Since I Don't Have You",
      "Rocket Queen",
      "Double Talkin' Jive",
      "Back Off Bitch",
      "Dust N' Bones",
      "Pretty Tied Up",
      "14 Years",
      "Dead Horse",
      "Right Next Door to Hell",
      "Garden of Eden",
      "Get in the Ring",
      "Bad Apples",
      "Perfect Crime",
      "Bad Obsession",
      "The Garden",
      "So Fine",
      "Shotgun Blues",
      "Coma",
      "Ain't It Fun",
      "Attitude",
      "Down on the Farm",
      "Hair of the Dog",
      "Mama Kin",
      "Reckless Life",
      "Move to the City",
      "Shadow of Your Love",
      "Nice Boys",
      "You Ain't the First",
      "Don't Damn Me",
      "Breakdown",
      "Locomotive",
      "Anything Goes",
      "Out Ta Get Me",
    ],
  },
  {
    name: "Pulp",
    songs: [
      "Common People",
      "Disco 2000",
      "Babies",
      "Do You Remember the First Time?",
      "This Is Hardcore",
      "Help the Aged",
      "Razzmatazz",
      "Sorted for E's & Wizz",
      "Something Changed",
      "Mile End",
      "Lipgloss",
      "Mis-Shapes",
      "Party Hard",
      "The Fear",
      "A Little Soul",
      "Underwear",
      "Like a Friend",
      "Sheffield: Sex City",
      "Monday Morning",
      "Live Bed Show",
      "Sylvia",
      "I Spy",
      "Joyriders",
      "Dishes",
      "Bar Italia",
      "O.U. (Gone, Gone)",
      "Little Girl (With Blue Eyes)",
      "Space",
      "Countdown",
      "The Trees",
      "My Legendary Girlfriend",
      "Styloroc (Nites of Suburbia)",
      "Down by the River",
      "After You",
      "His 'n' Hers",
      "Dogs Are Everywhere",
      "Have You Seen Her Lately?",
      "Pink Glove",
      "Seconds",
      "Don't You Want Me Anymore?",
      "Death Goes to the Disco",
      "There Was...",
      "You're a Nightmare",
      "Bad Cover Version",
      "The Never-Ending Story",
      "Love Is Blind",
      "Master of the Universe",
      "Blue Girls",
      "Death II",
      "Separations",
    ],
  },
  {
    name: "Frankie Goes to Hollywood",
    songs: [
      "Relax",
      "The Power of Love",
      "Two Tribes",
      "Welcome to the Pleasuredome",
      "Born to Run",
      "War",
      "Rage Hard",
      "Ferry Cross the Mersey",
      "Watching the Wildlife",
      "Warriors of the Wasteland",
      "Maximum Joy",
      "For Heaven's Sake",
      "Krisco Kisses",
      "Black Night White Light",
      "The Only Star in Heaven",
      "The Ballad of 32",
      "San José",
      "Wish the Lads Were Here",
      "Get It On",
      "Suffragette City",
      "Born to Run (Live)",
      "Relax (Come Fighting)",
      "Two Tribes (Annihilation Mix)",
      "Warriors of the Wasteland (Attack Mix)",
      "Watching the Wildlife (Hotter Mix)",
      "The Power of Love (Extended)",
      "Welcome to the Pleasuredome (Escape Act Video Mix)",
      "Rage Hard (Young Person's Guide to the 12\" Mix)",
      "Warriors of the Wasteland (The Attack Mix)",
      "Relax (Sex Mix)",
      "Two Tribes (Hibakusha Mix)",
      "The Power of Love (Rob Searle Club Mix)",
      "Welcome to the Pleasuredome (Brothers in Rhythm Rollercoaster Mix)",
      "Rage Hard (Voiceless)",
      "Warriors of the Wasteland (Montreux Mix)",
      "Watching the Wildlife (Voiceless)",
      "The Power of Love (Best Listened To by Lovers Mix)",
      "Relax (Ollie J Mix)",
      "Two Tribes (Apollo 440 Mix)",
      "Welcome to the Pleasuredome (Nalin & Kane Remix)",
      "Rage Hard (The Young Person's Guide to the 12\" Mix)",
      "Warriors of the Wasteland (Attack Full)",
      "Watching the Wildlife (Movement 2)",
      "The Power of Love (Rob Searle's Holiday Inn Dub)",
      "Relax (Lockout's Radio Edit)",
      "Two Tribes (Fluke's Magimix)",
      "Welcome to the Pleasuredome (Real Life Edit)",
      "Rage Hard (Paralyzer)",
      "Warriors of the Wasteland (Turn of the Knife Mix)",
    ],
  },
  {
    name: "Imagine Dragons",
    songs: [
      "Demons",
      "Believer",
      "Thunder",
      "Bones",
      "Radioactive",
      "It's Time",
      "Whatever It Takes",
      "On Top of the World",
      "Natural",
      "Shots",
      "I Bet My Life",
      "Gold",
      "Walking the Wire",
      "Bleeding Out",
      "Tiptoe",
      "Amsterdam",
      "Polaroid",
      "Monster",
      "Warriors",
      "Friction",
      "Zero",
      "Bad Liar",
      "Next to Me",
      "Not Today",
      "Rise Up",
      "Dream",
      "Hopeless Opus",
      "Who We Are",
      "Mouth of the River",
      "Battle Cry",
      "Thunder / Young Dumb & Broke",
      "Yesterday",
      "Love",
      "Hear Me",
      "Selene",
      "America",
      "Roots",
      "Levitate",
      "The River",
      "I'll Make It Up to You",
      "Start Over",
      "The Unknown",
      "Real Life",
      "West Coast",
      "I Was Me",
      "Walking the Wire (Live)",
      "Shots (Live)",
      "Second Chances",
      "Every Night",
      "I Don't Know Why",
      "Digital",
    ],
  },
  {
    name: "Garbage",
    songs: [
      "Only Happy When It Rains",
      "Stupid Girl",
      "I Think I'm Paranoid",
      "Cherry Lips (Go Baby Go!)",
      "#1 Crush",
      "Special",
      "Push It",
      "Queer",
      "When I Grow Up",
      "Why Do You Love Me",
      "Bleed Like Me",
      "The World Is Not Enough",
      "Milk",
      "Vow",
      "Androgyny",
      "Breaking Up the Girl",
      "Run Baby Run",
      "You Look So Fine",
      "Supervixen",
      "Temptation Waits",
      "Hammering in My Head",
      "Parade",
      "Cup of Coffee",
      "Dumb",
      "Control",
      "My Lover's Box",
      "The Trick Is to Keep Breathing",
      "As Heaven Is Wide",
      "Medication",
      "Happy Home",
      "Can't Cry These Tears",
      "Boys Wanna Fight",
      "Bad Boyfriend",
      "Battle in Me",
      "Not Your Kind of People",
      "Man on a Wire",
      "Automatic Systematic Habit",
      "Blood for Poppies",
      "Even Though Our Love Is Doomed",
      "Empty",
      "Magnetized",
      "Night Drive Loneliness",
      "Sometimes",
      "Blackout",
      "If I Lost You",
      "So Like a Rose",
      "Sex Is Not the Enemy",
      "Silence Is Golden",
      "Nobody Loves You",
      "The One",
    ],
  },
  {
    name: "The Jam",
    songs: [
      "Town Called Malice",
      "That's Entertainment",
      "Going Underground",
      "In the City",
      "Start!",
      "Down in the Tube Station at Midnight",
      "The Eton Rifles",
      "Beat Surrender",
      "Strange Town",
      "The Modern World",
      "David Watts",
      "News of the World",
      "All Around the World",
      "When You're Young",
      "The Bitterest Pill (I Ever Had to Swallow)",
      "Mr. Clean",
      "English Rose",
      "Man in the Corner Shop",
      "To Be Someone (Didn't We Have a Nice Time)",
      "'A' Bomb in Wardour Street",
      "In the Crowd",
      "Boy About Town",
      "Thick as Thieves",
      "Smithers-Jones",
      "Happy Together",
      "Ghosts",
      "Burning Sky",
      "Monday",
      "Private Hell",
      "The Butterfly Collector",
      "The Great Depression",
      "Shopping",
      "Tales from the Riverbank",
      "Carnation",
      "Life from a Window",
      "Sounds from the Street",
      "Art School",
      "Running on the Spot",
      "Bricks and Mortar",
      "I Got by in Time",
      "Time for Truth",
      "Standards",
      "Tonight at Noon",
      "The Place I Love",
      "Move on Up",
      "Don't Tell Them You're Sane",
      "I Need You (For Someone)",
      "Dreams of Children",
      "Heat Wave",
      "Carnaby Street",
    ],
  },
  {
    name: "Avril Lavigne",
    songs: [
      "Complicated",
      "I'm with You",
      "Sk8er Boi",
      "Girlfriend",
      "What the Hell",
      "My Happy Ending",
      "When You're Gone",
      "Nobody's Home",
      "Don't Tell Me",
      "Losing Grip",
      "Keep Holding On",
      "Wish You Were Here",
      "Hot",
      "He Wasn't",
      "Smile",
      "Let Me Go",
      "Rock N Roll",
      "What the Hell",
      "Hello Kitty",
      "Head Above Water",
      "Fall to Pieces",
      "Together",
      "Anything But Ordinary",
      "Things I'll Never Say",
      "Mobile",
      "Unwanted",
      "Tomorrow",
      "Naked",
      "How Does It Feel",
      "Freak Out",
      "Who Knows",
      "Forgotten",
      "Take Me Away",
      "Runaway",
      "I Always Get What I Want",
      "Slipped Away",
      "Contagious",
      "Innocence",
      "One of Those Girls",
      "Push",
      "Remember When",
      "Goodbye",
      "4 Real",
      "Bad Girl",
      "Give You What You Like",
      "Hello Heartache",
      "Hush Hush",
      "Falling Fast",
      "Sippin' on Sunshine",
      "You Ain't Seen Nothin' Yet",
      "How You Remind Me",
    ],
  },
  {
    name: "Rage Against the Machine",
    songs: [
      "Killing in the Name",
      "Bulls on Parade",
      "Guerrilla Radio",
      "Bombtrack",
      "Sleep Now in the Fire",
      "Testify",
      "Know Your Enemy",
      "Wake Up",
      "Freedom",
      "Down Rodeo",
      "Calm Like a Bomb",
      "Bullet in the Head",
      "Take the Power Back",
      "People of the Sun",
      "No Shelter",
      "Renegades of Funk",
      "Ashes in the Fall",
      "Revolver",
      "Year of tha Boomerang",
      "Vietnow",
      "Born of a Broken Man",
      "Mic Check",
      "Tire Me",
      "War Within a Breath",
      "Fistful of Steel",
      "Voice of the Voiceless",
      "Maria",
      "Wind Below",
      "Settle for Nothing",
      "Without a Face",
      "Beautiful World",
      "Down on the Street",
      "In My Eyes",
      "How I Could Just Kill a Man",
      "Maggie's Farm",
      "The Ghost of Tom Joad",
      "I'm Housin'",
      "The Narrows",
      "Hadda Be Playing on the Jukebox",
      "Darkness of Greed",
      "Mindset's a Threat",
      "Zapatas Blood",
      "Clampdown",
      "Black Steel in the Hour of Chaos",
      "Clear the Lane",
      "Funk Explosion",
      "Kick Out the Jams",
      "Auto Logic",
      "Hit the Deck",
      "Street Fighting Man",
    ],
  },
  {
    name: "The Outfield",
    songs: [
      "Your Love",
      "All the Love",
      "Say It Isn't So",
      "Since You've Been Gone",
      "Talk to Me",
      "Everytime You Cry",
      "Voices of Babylon",
      "My Paradise",
      "For You",
      "No Surrender",
      "Bangin' on My Heart",
      "61 Seconds",
      "Closer to Me",
      "One Hot Country",
      "Winning It All",
      "Mystery Man",
      "The Night Ain't Over",
      "I Don't Need Her",
      "Inside Your Skin",
      "A Long, Long Time Ago",
      "Part of Your Life",
      "Taking My Chances",
      "One of My Lies",
      "Taking My Chances",
      "Stranger in My Own Town",
      "Main Attraction",
      "Somewhere in America",
      "One Hot Country",
      "It's All About Love",
      "The Way It Should Be",
      "Love Is Everywhere",
      "Nervous Alibi",
      "Better Than Nothing",
      "No Point",
      "Moving Target",
      "Under a Stone",
      "On the Line",
      "Driving to Midnight",
      "Somewhere in America",
      "One Hot Country",
      "Love Will Find a Way",
      "Going Back",
      "It's All About Love",
      "Long Walk Back",
      "Talking About Us",
      "Call It Out",
      "Main Attraction",
      "Better Than Nothing",
      "Somewhere in America",
      "One Hot Country",
    ],
  },
  {
    name: "Backstreet Boys",
    songs: [
      "I Want It That Way",
      "Everybody (Backstreet's Back)",
      "As Long As You Love Me",
      "Shape of My Heart",
      "Quit Playing Games (With My Heart)",
      "Larger Than Life",
      "Show Me the Meaning of Being Lonely",
      "Incomplete",
      "Drowning",
      "All I Have to Give",
      "The One",
      "More Than That",
      "I'll Never Break Your Heart",
      "Get Down (You're the One for Me)",
      "We've Got It Goin' On",
      "The Call",
      "Just Want You to Know",
      "Inconsolable",
      "Straight Through My Heart",
      "Helpless When She Smiles",
      "Crawling Back to You",
      "Anywhere for You",
      "Larger Than Life (Live)",
      "Show Me the Meaning of Being Lonely (Live)",
      "Drowning (Live)",
      "Siberia",
      "Never Gone",
      "Panic",
      "Shattered",
      "Back to Your Heart",
      "Spanish Eyes",
      "Don't Want You Back",
      "Yes I Will",
      "The Answer to Our Life",
      "Everyone",
      "I Still...",
      "Safest Place to Hide",
      "How Did I Fall in Love with You",
      "Love Will Keep You Up All Night",
      "It's True",
      "Memories",
      "Set Adrift on Memory Bliss",
      "Hey Mr. DJ (Keep Playin' This Song)",
      "Time",
      "Not for Me",
      "All I Have to Give (A Cappella)",
      "10,000 Promises",
      "Every Time I Close My Eyes",
      "If You Want It to Be Good Girl (Get Yourself a Bad Boy)",
      "What Makes You Different (Makes You Beautiful)",
    ],
  },
  {
    name: "Britney Spears",
    songs: [
      "Toxic",
      "...Baby One More Time",
      "Gimme More",
      "Oops!... I Did It Again",
      "Womanizer",
      "Circus",
      "Piece of Me",
      "Everytime",
      "I'm a Slave 4 U",
      "Stronger",
      "(You Drive Me) Crazy",
      "Lucky",
      "Overprotected",
      "Me Against the Music",
      "Break the Ice",
      "Hold It Against Me",
      "If U Seek Amy",
      "Born to Make You Happy",
      "Sometimes",
      "Till the World Ends",
      "I Wanna Go",
      "Work Bitch",
      "Don't Let Me Be the Last to Know",
      "Radar",
      "My Prerogative",
      "Do Somethin'",
      "I'm Not a Girl, Not Yet a Woman",
      "Boys",
      "3",
      "Slumber Party",
      "Perfume",
      "Alien",
      "Cinderella",
      "Unusual You",
      "Blur",
      "Lace and Leather",
      "Mannequin",
      "Shattered Glass",
      "Out from Under",
      "Kill the Lights",
      "Mmm Papi",
      "Rock Me In",
      "Quicksand",
      "Amnesia",
      "My Baby",
      "Get Back",
      "Everybody",
      "Why Should I Be Sad",
      "Let Me Be",
      "That's Where You Take Me",
    ],
  },
  {
    name: "Mary J. Blige",
    songs: [
      "Family Affair",
      "Be Without You",
      "Mary Jane",
      "Real Love",
      "One",
      "No More Drama",
      "Not Gon' Cry",
      "I'm Going Down",
      "You Remind Me",
      "Everything",
      "Just Fine",
      "Love Is All We Need",
      "I Can Love You",
      "Share My World",
      "Sweet Thing",
      "All That I Can Say",
      "Give Me You",
      "Seven Days",
      "Love No Limit",
      "My Life",
      "You Bring Me Joy",
      "Take Me as I Am",
      "I Am",
      "Deep Inside",
      "Our Love",
      "MJB Da MVP",
      "Enough Cryin'",
      "Missing You",
      "I Found My Everything",
      "Be Happy",
      "Can't Hide from Luv",
      "In the Meantime",
      "Don't Mind",
      "No One Will Do",
      "Get to Know You Better",
      "Keep Your Head",
      "I Feel Good",
      "Can't Get Enough",
      "A Night to Remember",
      "Steal Away",
      "Baggage",
      "The One",
      "About You",
      "Beautiful Day",
      "Stay Down",
      "Give Me Your Love",
      "Feel Inside",
      "Someone to Love Me (Naked)",
      "Therapy",
      "Doubt",
      "Whole Damn Year",
      "Nobody but You",
    ],
  },
  {
    name: "Suede",
    songs: [
      "Beautiful Ones",
      "Trash",
      "Animal Nitrate",
      "The Wild Ones",
      "So Young",
      "Stay Together",
      "The Drowners",
      "We Are the Pigs",
      "She's in Fashion",
      "Filmstar",
      "Saturday Night",
      "Electricity",
      "Can't Get Enough",
      "Everything Will Flow",
      "New Generation",
      "Metal Mickey",
      "My Insatiable One",
      "Heroine",
      "The Next Life",
      "Sleeping Pills",
      "Pantomime Horse",
      "By the Sea",
      "Breakdown",
      "Still Life",
      "To the Birds",
      "My Dark Star",
      "Killing of a Flash Boy",
      "Together",
      "Europe Is Our Playground",
      "Young Men",
      "The Big Time",
      "The Asphalt World",
      "He's Dead",
      "The 2 of Us",
      "Down",
      "High Rising",
      "The Sound of the Streets",
      "The Living Dead",
      "This Hollywood Life",
      "Picnic by the Motorway",
      "Attitude",
      "Obsessions",
      "It Starts and Ends with You",
      "I Don't Know How to Reach You",
      "Sabotage",
      "Tightrope",
      "What Violet Says",
      "The Fur & the Feathers",
      "Pale Snow",
      "Learning to Be",
    ],
  },
  // Ordered to here
  {
    name: "Ultravox",
    songs: [
      "Vienna",
      "Dancing with Tears in My Eyes",
      "Hymn",
      "The Voice",
      "Reap the Wild Wind",
      "Sleepwalk",
      "All Stood Still",
      "Passing Strangers",
      "Visions in Blue",
      "Lament",
      "Love's Great Adventure",
      "We Came to Dance",
      "One Small Day",
      "Young Savage",
      "New Europeans",
      "Slow Motion",
      "White China",
      "Mine for Life",
      "The Thin Wall",
      "I Remember (Death in the Afternoon)",
      "Man of Two Worlds",
      "Heart of the Country",
      "Private Lives",
      "All in One Day",
      "Western Promise",
      "When the Scream Subsides",
      "Cut and Run",
      "The Ascent",
      "Face to Face",
      "Blue Light",
      "A Friend I Call Desire",
      "Your Name (Has Slipped My Mind Again)",
      "Distant Smile",
      "Just for a Moment",
      "The Song (We Go)",
      "Astrodyne",
      "The New Frontier",
      "Brilliant",
      "Dancing with Tears in My Eyes (Live)",
      "The Voice (Live)",
      "We Stand Alone",
      "Accent on Youth",
      "Dangerous Rhythm",
      "Wide Boys",
      "Quiet Men",
      "Dislocation",
      "My Sex",
      "I Want to Be a Machine",
      "Hiroshima Mon Amour",
      "The Frozen Ones",
    ],
  },
  {
    name: "30 Seconds to Mars",
    songs: [
      "The Kill (Bury Me)",
      "Closer to the Edge",
      "Kings and Queens",
      "This Is War",
      "From Yesterday",
      "Attack",
      "A Beautiful Lie",
      "Hurricane",
      "Do or Die",
      "Up in the Air",
      "City of Angels",
      "Edge of the Earth",
      "Night of the Hunter",
      "The Fantasy",
      "Conquistador",
      "End of All Days",
      "Alibi",
      "Stranger in a Strange Land",
      "Search and Destroy",
      "Vox Populi",
      "Bright Lights",
      "Revenge",
      "The Race",
      "Pyres of Varanasi",
      "Birth",
      "Northern Lights",
      "Battle of One",
      "Savior",
      "R-Evolve",
      "100 Suns",
      "Oblivion",
      "Echelon",
      "The Story",
      "The Mission",
      "Was It a Dream?",
      "Welcome to the Universe",
      "Buddha for Mary",
      "Year Zero",
      "Hunter",
      "The Believer",
      "The Resistor",
      "Witness",
      "Rise",
      "Stay",
      "Revelation",
      "Tomorrow",
      "Rescue Me",
      "Dangerous Night",
      "Great Wide Open",
      "Remedy",
    ],
  },
  {
    name: "Blur",
    songs: [
      "Song 2",
      "Parklife",
      "Girls & Boys",
      "Coffee & TV",
      "Tender",
      "The Universal",
      "Beetlebum",
      "Country House",
      "End of a Century",
      "Charmless Man",
      "She's So High",
      "There's No Other Way",
      "To the End",
      "Tracy Jacks",
      "Out of Time",
      "For Tomorrow",
      "This Is a Low",
      "Popscene",
      "Badhead",
      "Chemical World",
      "Clover Over Dover",
      "He Thought of Cars",
      "Oily Water",
      "M.O.R.",
      "Advert",
      "Sunday Sunday",
      "Jubilee",
      "No Distance Left to Run",
      "On Your Own",
      "Stereotypes",
      "Under the Westway",
      "Battery in Your Leg",
      "Bugman",
      "Bank Holiday",
      "London Loves",
      "Girls and Boys (Pet Shop Boys Remix)",
      "Battle",
      "It Could Be You",
      "Blue Jeans",
      "Villa Rosie",
      "Come Together",
      "Sing",
      "Ambulance",
      "Bang",
      "Death of a Party",
      "Essex Dogs",
      "Caramel",
      "Trailerpark",
      "Top Man",
      "Turn It Up",
    ],
  },
  {
    name: "Smash Mouth",
    songs: [
      "All Star",
      "Walkin' on the Sun",
      "I'm a Believer",
      "Can't Get Enough of You Baby",
      "Then the Morning Comes",
      "Why Can't We Be Friends",
      "Pacific Coast Party",
      "Come On, Come On",
      "Waste",
      "Hot",
      "Holiday in My Head",
      "You Are My Number One",
      "Getting Better",
      "Magic",
      "Your Man",
      "Better Do It Right",
      "Stoned",
      "Pet Names",
      "Padrino",
      "Do It Again",
      "Can't Get Enough of You Baby (Live)",
      "Let's Rock",
      "Who's There",
      "Road Man",
      "I Just Wanna See",
      "Nervous in the Alley",
      "Flo",
      "Home",
      "Satellite",
      "Strung",
      "Everyday Superhero",
      "Story of My Life",
      "So Insane",
      "Sister Psychic",
      "Heave-Ho",
      "Beer Goggles",
      "Diggin' Your Scene",
      "Keep It Down",
      "Walking on the Sun (Instrumental)",
      "Heave-Ho (Live)",
      "The Crawl",
      "Force Field",
      "Connect the Dots",
      "Old Habits",
      "Right Side, Wrong Bed",
      "Sorry About Your Penis",
      "Dressin' Up",
      "Live to Love Another Day",
      "She's Into Me",
      "Every Word Means No",
    ],
  },
  {
    name: "MGMT",
    songs: [
      "Kids",
      "Electric Feel",
      "Time to Pretend",
      "Little Dark Age",
      "The Youth",
      "Weekend Wars",
      "Congratulations",
      "Alien Days",
      "Flash Delirium",
      "Me and Michael",
      "She Works Out Too Much",
      "When You Die",
      "Siberian Breaks",
      "Of Moons, Birds & Monsters",
      "TSLAMP",
      "In the Afternoon",
      "Hand It Over",
      "Plenty of Girls in the Sea",
      "James",
      "Brian Eno",
      "Lady Dada's Nightmare",
      "Astro-Mancy",
      "Your Life Is a Lie",
      "Mystery Disease",
      "I Found a Whistle",
      "Indie Rokkers",
      "4th Dimensional Transition",
      "Future Reflections",
      "It's Working",
      "Introspection",
      "Song for Dan Treacy",
      "Someone's Missing",
      "Siberian Breaks",
      "Flash Delirium",
      "Of Moons, Birds & Monsters",
      "Brian Eno",
      "Lady Dada's Nightmare",
      "I Found a Whistle",
      "It's Working",
      "Introspection",
      "Weekend Wars",
      "The Handshake",
      "Future Reflections",
      "Kids (Afterschool Dance Megamix)",
      "Metanoia",
      "Cool Song No. 2",
      "Your Life Is a Lie",
      "TSLAMP",
      "James",
      "In the Afternoon",
    ],
  },
  {
    name: "Usher",
    songs: [
      "Yeah!",
      "Burn",
      "U Got It Bad",
      "My Boo",
      "You Make Me Wanna...",
      "Confessions Part II",
      "DJ Got Us Fallin' in Love",
      "Love in This Club",
      "Nice & Slow",
      "OMG",
      "U Don't Have to Call",
      "Caught Up",
      "There Goes My Baby",
      "Climax",
      "Can U Handle It?",
      "You Remind Me",
      "Hey Daddy (Daddy's Home)",
      "Trading Places",
      "Lil Freak",
      "Here I Stand",
      "Scream",
      "Good Kisser",
      "Moving Mountains",
      "Numb",
      "More",
      "U-Turn",
      "Pop Ya Collar",
      "His Mistakes",
      "Love in This Club Part II",
      "Seduction",
      "Lovers and Friends",
      "Euphoria",
      "Stranger",
      "Don't Waste My Time",
      "There Goes My Baby (Remix)",
      "Confessions Part II (Remix)",
      "U Got It Bad (Remix)",
      "Yeah! (Remix)",
      "Burn (Remix)",
      "You Make Me Wanna... (Remix)",
      "Nice & Slow (Remix)",
      "OMG (Remix)",
      "U Don't Have to Call (Remix)",
      "Caught Up (Remix)",
      "Hey Daddy (Daddy's Home) (Remix)",
      "Trading Places (Remix)",
      "Lil Freak (Remix)",
      "You Remind Me (Remix)",
      "Scream (Remix)",
    ],
  },
  {
    name: "The Cranberries",
    songs: [
      "Zombie",
      "Linger",
      "Dreams",
      "Ode to My Family",
      "When You're Gone",
      "Salvation",
      "Ridiculous Thoughts",
      "Animal Instinct",
      "I Can't Be with You",
      "Just My Imagination",
      "Promises",
      "Free to Decide",
      "Electric Blue",
      "Daffodil Lament",
      "This Is the Day",
      "Analyse",
      "You and Me",
      "Still Can't...",
      "Wanted",
      "I Still Do",
      "Sunday",
      "Not Sorry",
      "Pretty",
      "How",
      "Put Me Down",
      "Liar",
      "Empty",
      "Disappointment",
      "Yeats' Grave",
      "War Child",
      "Go Your Own Way",
      "Will You Remember?",
      "Waltzing Back",
      "Cordell",
      "Joe",
      "Twenty One",
      "Delilah",
      "Hollywood",
      "Saving Grace",
      "Carry On",
      "Show Me",
      "Time Is Ticking Out",
      "In the Ghetto",
      "New New York",
      "Stars",
      "The Rebels",
      "Pathetic Senses",
      "Serendipity",
      "The Icicle Melts",
      "Forever Yellow Skies",
    ],
  },
  {
    name: "The Verve",
    songs: [
      "Bitter Sweet Symphony",
      "The Drugs Don't Work",
      "Lucky Man",
      "Sonnet",
      "Love Is Noise",
      "Slide Away",
      "History",
      "This Is Music",
      "On Your Own",
      "Weeping Willow",
      "Velvet Morning",
      "Catching the Butterfly",
      "Space and Time",
      "Blue",
      "Life's an Ocean",
      "Already There",
      "All in the Mind",
      "Gravity Grave",
      "She's a Superstar",
      "The Sun, the Sea",
      "Man Called Sun",
      "Make It Till Monday",
      "Neon Wilderness",
      "Stormy Clouds",
      "So It Goes",
      "One Day",
      "Lucky Man (Acoustic)",
      "Monte Carlo",
      "Deep Freeze",
      "Come On",
      "Bitter Sweet Symphony (Extended Version)",
      "The Rolling People",
      "Virtual World",
      "South Pacific",
      "Let the Damage Begin",
      "Staring Stranger",
      "3 Steps",
      "Echo Bass",
      "Jalfrezi",
      "Mover",
      "Where the Geese Go",
      "Reprise",
      "Country Song",
      "Life's Not a Rehearsal",
      "Twilight",
      "Endless Life",
      "Echoes",
      "Celtic Spring",
      "Major Force",
      "All Night Long",
    ],
  },
  {
    name: "TLC",
    songs: [
      "Waterfalls",
      "No Scrubs",
      "Creep",
      "Unpretty",
      "Red Light Special",
      "Ain't 2 Proud 2 Beg",
      "Diggin' on You",
      "Baby-Baby-Baby",
      "What About Your Friends",
      "Girl Talk",
      "Hat 2 da Back",
      "Kick Your Game",
      "Silly Ho",
      "Hands Up",
      "Damaged",
      "Dear Lie",
      "Switch",
      "If I Was Your Girlfriend",
      "My Life",
      "Come Get Some",
      "Get It Up",
      "Way Back",
      "Over Me",
      "Sumthin' Wicked This Way Comes",
      "Case of the Fake People",
      "Meant to Be",
      "I'm Good at Being Bad",
      "All That",
      "His Story",
      "In Your Arms Tonight",
      "I Miss You So Much",
      "Give It to Me While It's Hot",
      "Start a Fire",
      "It's Sunny",
      "Depend on Myself",
      "Das da Way We Like 'Em",
      "Let's Do It Again",
      "Shout",
      "Whoop de Woo",
      "Shock Dat Monkey",
      "It's Your World",
      "Kick Your Game (Remix)",
      "No Scrubs (Rap Version)",
      "Unpretty (Remix)",
      "Waterfalls (Remix)",
      "Creep (Remix)",
      "Red Light Special (Remix)",
      "What About Your Friends (Remix)",
      "Girl Talk (Remix)",
      "Dear Lie (Remix)",
    ],
  },
  {
    name: "The Bangles",
    songs: [
      "Walk Like an Egyptian",
      "Eternal Flame",
      "Manic Monday",
      "Hazy Shade of Winter",
      "If She Knew What She Wants",
      "In Your Room",
      "Hero Takes a Fall",
      "Be with You",
      "Walking Down Your Street",
      "Going Down to Liverpool",
      "I'll Set You Free",
      "Following",
      "James",
      "More Than Meets the Eye",
      "September Gurls",
      "Complicated Girl",
      "Watching the Sky",
      "Angels Don't Fall in Love",
      "Let It Go",
      "Some Dreams Come True",
      "Where Were You When I Needed You",
      "Tear Off Your Own Head (It's a Doll Revolution)",
      "Ride the Ride",
      "The Rain Song",
      "Stealing Rosemary",
      "Between the Two",
      "Here Right Now",
      "Mixed Messages",
      "I Will Take Care of You",
      "Get the Girl",
      "Call on Me",
      "Mesmerized",
      "I Got Nothing",
      "Everything I Wanted",
      "Crash and Burn",
      "Make a Play for Her Now",
      "Silent Treatment",
      "Standing in the Hallway",
      "Following (Live)",
      "What I Meant to Say",
      "I'm In Line",
      "Not Like You",
      "Tell Me",
      "Restless",
      "I'll Never Be Through with You",
      "Through Your Eyes",
      "Open My Eyes",
      "Dover Beach",
      "The Real World",
      "How Is the Air Up There?",
    ],
  },
  {
    name: "Modest Mouse",
    songs: [
      "Float On",
      "Dashboard",
      "Ocean Breathes Salty",
      "Lampshades on Fire",
      "The World at Large",
      "Missed the Boat",
      "Fire It Up",
      "King Rat",
      "Bury Me With It",
      "Satin in a Coffin",
      "Paper Thin Walls",
      "3rd Planet",
      "Gravity Rides Everything",
      "Doin' the Cockroach",
      "Trailer Trash",
      "Night on the Sun",
      "Bankrupt on Selling",
      "Tiny Cities Made of Ashes",
      "Dark Center of the Universe",
      "Interstate 8",
      "Never Ending Math Equation",
      "Broke",
      "The Ground Walks, with Time in a Box",
      "Ansel",
      "Dramamine",
      "The View",
      "Black Cadillacs",
      "Blame It on the Tetons",
      "The Best Room",
      "Pistol (A. Cunanan, Miami, FL. 1996)",
      "Poison the Well",
      "Satellite Skin",
      "People as Places as People",
      "Parting of the Sensory",
      "March into the Sea",
      "Education",
      "The Whale Song",
      "Perpetual Motion Machine",
      "King Rat",
      "Good News for People Who Love Bad News",
    ],
  },
  {
    name: "Justin Timberlake",
    songs: [
      "Cry Me a River",
      "SexyBack",
      "Mirrors",
      "Can't Stop the Feeling!",
      "Rock Your Body",
      "What Goes Around... Comes Around",
      "My Love",
      "Suit & Tie",
      "Like I Love You",
      "LoveStoned",
      "TKO",
      "Not a Bad Thing",
      "Summer Love",
      "Say Something",
      "Filthy",
      "Until the End of Time",
      "Drink You Away",
      "Take Back the Night",
      "Let the Groove Get In",
      "True Blood",
      "Only When I Walk Away",
      "Tunnel Vision",
      "Blue Ocean Floor",
      "Pusher Love Girl",
      "Strawberry Bubblegum",
      "That Girl",
      "Amnesia",
      "Spaceship Coupe",
      "Gimme What I Don't Know (I Want)",
      "You Got It On",
      "Electric Lady",
      "Cabaret",
      "Dress On",
      "Blindness",
      "Another Song (All Over Again)",
      "Murder",
      "One Night Only",
      "Sexy Ladies",
      "Damn Girl",
      "Right for Me",
      "Pose",
      "Still on My Brain",
      "And She Said",
      "Good Foot",
      "Love Don't Love Me",
      "Worthy Of",
      "What Goes Around...",
      "Take Me Now",
      "Lose My Way",
      "Supplies",
    ],
  },
  {
    name: "The Raconteurs",
    songs: [
      "Steady, As She Goes",
      "Salute Your Solution",
      "Many Shades of Black",
      "Consoler of the Lonely",
      "Broken Boy Soldier",
      "Hands",
      "Level",
      "Together",
      "Rich Kid Blues",
      "Blue Veins",
      "Old Enough",
      "Top Yourself",
      "Switch and the Spur",
      "Attention",
      "Five on the Five",
      "Carolina Drama",
      "Yellow Sun",
      "Intimate Secretary",
      "Store Bought Bones",
      "These Stones Will Shout",
      "Pull This Blanket Off",
      "You Don't Understand Me",
      "The Bane Rendition",
      "Keep It Clean",
      "Call It a Day",
      "Shine the Light on Me",
      "Sunday Driver",
      "Help Me Stranger",
      "Don't Bother Me",
      "Live a Lie",
      "Now That You're Gone",
      "Somedays (I Don't Feel Like Trying)",
      "Hey Gyp (Dig the Slowness)",
      "Only Child",
      "What's Yours Is Mine",
      "All Hands",
      "Thoughts and Prayers",
      "Hold Up",
      "This Life",
      "Too Soon",
      "Crazy",
      "Headin' for the Texas Border",
      "Love Interruption",
      "You and I",
      "I Cut Like a Buffalo",
      "The Rose with the Broken Neck",
      "I'm Shakin'",
      "Take Care of Business",
      "Sixteen Saltines",
      "Freedom at 21",
    ],
  },
  {
    name: "Katy Perry",
    songs: [
      "Roar",
      "Dark Horse",
      "Firework",
      "California Gurls",
      "Teenage Dream",
      "I Kissed a Girl",
      "Last Friday Night (T.G.I.F.)",
      "Hot n Cold",
      "E.T.",
      "Part of Me",
      "The One That Got Away",
      "Wide Awake",
      "Unconditionally",
      "This Is How We Do",
      "Birthday",
      "Swish Swish",
      "Bon Appétit",
      "Chained to the Rhythm",
      "Never Really Over",
      "Harleys in Hawaii",
      "Daisies",
      "Smile",
      "Walking on Air",
      "Peacock",
      "Hummingbird Heartbeat",
      "Legendary Lovers",
      "Ghost",
      "Love Me",
      "Double Rainbow",
      "By the Grace of God",
      "This Moment",
      "Act My Age",
      "Not Like the Movies",
      "One of the Boys",
      "Waking Up in Vegas",
      "Thinking of You",
      "If You Can Afford Me",
      "Self Inflicted",
      "Fingerprints",
      "Mannequin",
      "Lost",
      "I'm Still Breathing",
      "Ur So Gay",
      "Dressin' Up",
      "Circle the Drain",
      "Pearl",
      "Who Am I Living For?",
      "Spiritual",
      "It Takes Two",
      "Choose Your Battles",
    ],
  },
  {
    name: "Yeah Yeah Yeahs",
    songs: [
      "Maps",
      "Heads Will Roll",
      "Gold Lion",
      "Zero",
      "Y Control",
      "Date with the Night",
      "Turn Into",
      "Sacrilege",
      "Cheated Hearts",
      "Soft Shock",
      "Pin",
      "Runaway",
      "Down Boy",
      "Hysteric",
      "Black Tongue",
      "Under the Earth",
      "Way Out",
      "Dull Life",
      "Tick",
      "Mysteries",
      "Phenomena",
      "Honeybear",
      "Warrior",
      "Skeletons",
      "Despair",
      "Buried Alive",
      "Area 52",
      "Shame and Fortune",
      "Dragon Queen",
      "Man",
      "Mosquito",
      "Heads Will Roll (A-Trak Remix)",
      "Art Star",
      "Kiss Kiss",
      "Poor Song",
      "These Paths",
      "Little Shadow",
      "Fancy",
      "Black Wave",
      "Modern Romance",
    ],
  },
  {
    name: "The Notorious B.I.G.",
    songs: [
      "Juicy",
      "Big Poppa",
      "Hypnotize",
      "Mo Money Mo Problems",
      "One More Chance",
      "Notorious Thugs",
      "Ten Crack Commandments",
      "Who Shot Ya?",
      "Gimme the Loot",
      "Sky's the Limit",
      "Everyday Struggle",
      "Warning",
      "Unbelievable",
      "Kick in the Door",
      "N****s Bleed",
      "Going Back to Cali",
      "Suicidal Thoughts",
      "Party and Bullshit",
      "What's Beef",
      "I Got a Story to Tell",
      "Big Poppa (Remix)",
      "Notorious B.I.G.",
      "Come On",
      "Dead Wrong",
      "Things Done Changed",
      "Respect",
      "Machine Gun Funk",
      "My Downfall",
      "You're Nobody (Til Somebody Kills You)",
      "Playa Hater",
      "I Love the Dough",
      "Friend of Mine",
      "Miss U",
      "Me & My B****",
      "Another",
      "Somebody's Gotta Die",
      "Nasty Boy",
      "Going Back to Cali (Remix)",
      "Living the Life",
      "Would You Die for Me",
      "Rap Phenomenon",
      "Dead Wrong (Remix)",
      "Running Your Mouth",
      "Want That Old Thing Back",
      "One More Chance (Remix)",
      "The What",
      "Niggas Bleed (Remix)",
      "Just Playing (Dreams)",
      "Another (Remix)",
      "I'm Wit Whateva",
    ],
  },
  {
    name: "Crowded House",
    songs: [
      "Don't Dream It's Over",
      "Weather with You",
      "Something So Strong",
      "Better Be Home Soon",
      "Fall at Your Feet",
      "Distant Sun",
      "Four Seasons in One Day",
      "Into Temptation",
      "It's Only Natural",
      "Mean to Me",
      "When You Come",
      "Private Universe",
      "Chocolate Cake",
      "Locked Out",
      "World Where You Live",
      "I Feel Possessed",
      "Sister Madly",
      "Nails in My Feet",
      "Pineapple Head",
      "In My Command",
      "Not the Girl You Think You Are",
      "Fingers of Love",
      "There Goes God",
      "Instinct",
      "She Called Up",
      "Recurring Dream",
      "Walking on the Spot",
      "I Walk Away",
      "Kare Kare",
      "Hole in the River",
      "Silent House",
      "Pour le Monde",
      "To the Island",
      "Black and White Boy",
      "Catherine Wheels",
      "Whispers and Moans",
      "In the Lowlands",
      "You Are the One to Make Me Cry",
      "Even a Child",
      "Nobody Wants To",
      "Amsterdam",
      "Tall Trees",
      "People Are Like Suns",
      "Help Is Coming",
      "All I Ask",
      "Pour le Monde",
      "A Sigh",
      "Love You 'Til the Day I Die",
      "Don't Stop Now",
      "Choc-tic",
    ],
  },
  {
    name: "Aaliyah",
    songs: [
      "Are You That Somebody",
      "One in a Million",
      "Try Again",
      "Rock the Boat",
      "At Your Best (You Are Love)",
      "Back & Forth",
      "4 Page Letter",
      "We Need a Resolution",
      "I Care 4 U",
      "If Your Girl Only Knew",
      "The One I Gave My Heart To",
      "More Than a Woman",
      "Miss You",
      "Hot Like Fire",
      "Age Ain't Nothing But a Number",
      "Journey to the Past",
      "I Don't Wanna",
      "Come Over",
      "Loose Rap",
      "Never No More",
      "Extra Smooth",
      "I Refuse",
      "Don't Know What to Tell Ya",
      "Choosey Lover",
      "Down with the Clique",
      "Street Thing",
      "Young Nation",
      "Man Undercover",
      "Heartbroken",
      "Read Between the Lines",
      "Ladies in da House",
      "Giving You More",
      "Old School",
      "Throw Your Hands Up",
      "Got to Give It Up",
      "No One Knows How to Love Me Quite Like You Do",
      "Turn the Page",
      "It's Whatever",
      "Everythings Gonna Be Alright",
      "The Thing I Like",
      "Never Comin' Back",
      "Are You Feelin' Me?",
      "I'm Down",
      "I Gotcha' Back",
      "Back & Forth (Remix)",
      "Hot Like Fire (Remix)",
      "Are You That Somebody (Remix)",
      "Try Again (Remix)",
      "We Need a Resolution (Remix)",
    ],
  },
  {
    name: "Tears for Fears",
    songs: [
      "Everybody Wants to Rule the World",
      "Shout",
      "Mad World",
      "Head Over Heels",
      "Sowing the Seeds of Love",
      "Pale Shelter",
      "Woman in Chains",
      "Change",
      "Advice for the Young at Heart",
      "Break It Down Again",
      "Memories Fade",
      "The Working Hour",
      "I Believe",
      "Laid So Low (Tears Roll Down)",
      "Mothers Talk",
      "Goodnight Song",
      "Cold",
      "Famous Last Words",
      "Swords and Knives",
      "Year of the Knife",
      "Badman's Song",
      "Ideas as Opiates",
      "The Hurting",
      "Start of the Breakdown",
      "Watch Me Bleed",
      "The Way You Are",
      "Raoul and the Kings of Spain",
      "God's Mistake",
      "Secret World",
      "Call Me Mellow",
      "Closest Thing to Heaven",
      "Everybody Loves a Happy Ending",
      "Elemental",
      "Power",
      "Brian Wilson Said",
      "Sketches of Pain",
      "Sorry",
      "Size of Sorrow",
      "Until I Drown",
      "Ladybird",
    ],
  },
  {
    name: "Evanescence",
    songs: [
      "Bring Me to Life",
      "My Immortal",
      "Going Under",
      "Call Me When You're Sober",
      "Lithium",
      "Everybody's Fool",
      "Sweet Sacrifice",
      "What You Want",
      "Good Enough",
      "Tourniquet",
      "Haunted",
      "Imaginary",
      "My Last Breath",
      "Whisper",
      "Weight of the World",
      "All That I'm Living For",
      "Cloud Nine",
      "Lost in Paradise",
      "Hello",
      "Taking Over Me",
      "Anywhere",
      "Lacrymosa",
      "Made of Stone",
      "Erase This",
      "Sick",
      "End of the Dream",
      "Oceans",
      "The Change",
      "Never Go Back",
      "Swimming Home",
      "Your Star",
      "Farther Away",
      "Missing",
      "Breathe No More",
      "Together Again",
      "Say You Will",
      "The Other Side",
      "Disappear",
      "Even in Death",
      "Field of Innocence",
      "Forgive Me",
      "Understanding",
      "Before the Dawn",
      "If You Don't Mind",
      "New Way to Bleed",
      "Secret Door",
      "A New Horizon",
      "Surrender",
      "Perfect Dream",
      "Take Cover",
    ],
  },
  {
    name: "Metallica",
    songs: [
      "Enter Sandman",
      "Nothing Else Matters",
      "The Unforgiven",
      "Master of Puppets",
      "One",
      "Fade to Black",
      "Sad But True",
      "For Whom the Bell Tolls",
      "Battery",
      "Seek & Destroy",
      "Wherever I May Roam",
      "Creeping Death",
      "Whiskey in the Jar",
      "The Memory Remains",
      "Harvester of Sorrow",
      "Fuel",
      "Welcome Home (Sanitarium)",
      "Until It Sleeps",
      "Hero of the Day",
      "The Four Horsemen",
      "Blackened",
      "Fight Fire with Fire",
      "And Justice for All",
      "Ride the Lightning",
      "The Day That Never Comes",
      "Moth Into Flame",
      "Hardwired",
      "Atlas, Rise!",
      "Spit Out the Bone",
      "The Call of Ktulu",
      "King Nothing",
      "St. Anger",
      "Frantic",
      "The Outlaw Torn",
      "The Frayed Ends of Sanity",
      "Dyers Eve",
      "Disposable Heroes",
      "Leper Messiah",
      "Whiplash",
      "My Friend of Misery",
      "Damage, Inc.",
      "Cyanide",
      "That Was Just Your Life",
      "All Nightmare Long",
      "Broken, Beat & Scarred",
      "The End of the Line",
      "The Judas Kiss",
      "ManUNkind",
      "Am I Savage?",
      "Murder One",
    ],
  },
  {
    name: "The Smiths",
    songs: [
      "There Is a Light That Never Goes Out",
      "This Charming Man",
      "How Soon Is Now?",
      "Bigmouth Strikes Again",
      "Panic",
      "Heaven Knows I'm Miserable Now",
      "The Boy with the Thorn in His Side",
      "Ask",
      "Please, Please, Please, Let Me Get What I Want",
      "Girlfriend in a Coma",
      "William, It Was Really Nothing",
      "What Difference Does It Make?",
      "Hand in Glove",
      "Some Girls Are Bigger Than Others",
      "Shoplifters of the World Unite",
      "I Know It's Over",
      "That Joke Isn't Funny Anymore",
      "Last Night I Dreamt That Somebody Loved Me",
      "Sheila Take a Bow",
      "Still Ill",
      "Back to the Old House",
      "Well I Wonder",
      "You've Got Everything Now",
      "Please Help the Cause Against Loneliness",
      "Jeane",
      "Rubber Ring",
      "Half a Person",
      "London",
      "Meat Is Murder",
      "Nowhere Fast",
      "These Things Take Time",
      "Sweet and Tender Hooligan",
      "Death at One's Elbow",
      "I Want the One I Can't Have",
      "Frankly, Mr. Shankly",
      "Asleep",
      "Unloveable",
      "Cemetery Gates",
      "This Night Has Opened My Eyes",
      "Pretty Girls Make Graves",
    ],
  },
  {
    name: "Blondie",
    songs: [
      "Heart of Glass",
      "Call Me",
      "One Way or Another",
      "Rapture",
      "The Tide Is High",
      "Atomic",
      "Hanging on the Telephone",
      "Dreaming",
      "Maria",
      "Sunday Girl",
      "Union City Blue",
      "Denis",
      "Picture This",
      "In the Flesh",
      "Rip Her to Shreds",
      "X Offender",
      "Island of Lost Souls",
      "Accidents Never Happen",
      "I'm Always Touched by Your Presence Dear",
      "Fade Away and Radiate",
      "Die Young Stay Pretty",
      "Dance Away",
      "War Child",
      "Eat to the Beat",
      "Shayla",
      "Slow Motion",
      "Living in the Real World",
      "The Hardest Part",
      "11:59",
      "A Shark in Jets Clothing",
      "Fan Mail",
      "I Know but I Don't Know",
      "Atomic 79",
      "Pretty Baby",
      "Man Overboard",
      "Union City Blue (Diddy's Power & Passion Edit)",
      "Hanging on the Telephone (Remix)",
      "Heart of Glass (Remix)",
      "One Way or Another (Remix)",
      "Rapture Riders",
      "The Tide Is High (Sand Dollar Mix)",
      "Maria (Soul Solution Radio Edit)",
      "Sunday Girl (French Version)",
      "In the Flesh (Extended Version)",
      "Rip Her to Shreds (Extended Version)",
      "X Offender (Extended Version)",
      "Island of Lost Souls (Extended Version)",
      "Accidents Never Happen (Extended Version)",
      "I'm Always Touched by Your Presence Dear (Extended Version)",
    ],
  },
  {
    name: "Alicia Keys",
    songs: [
      "Fallin'",
      "No One",
      "If I Ain't Got You",
      "Girl on Fire",
      "Empire State of Mind (Part II) Broken Down",
      "You Don't Know My Name",
      "Un-Thinkable (I'm Ready)",
      "A Woman's Worth",
      "Try Sleeping with a Broken Heart",
      "Superwoman",
      "Like You'll Never See Me Again",
      "Karma",
      "Diary",
      "Fire We Make",
      "Doesn't Mean Anything",
      "Teenage Love Affair",
      "Brand New Me",
      "We Are Here",
      "In Common",
      "Blended Family (What You Do for Love)",
      "Show Me Love",
      "Time Machine",
      "Underdog",
      "Good Job",
      "How Come You Don't Call Me",
      "New Day",
      "Distance and Time",
      "Put It in a Love Song",
      "Lesson Learned",
      "Love Is Blind",
      "Wait Til You See My Smile",
      "Unbreakable",
      "How It Feels to Fly",
      "When You Really Love Someone",
      "Butterflyz",
      "Lover Man",
      "Heartburn",
      "This Bed",
      "That's How Strong My Love Is",
      "Another Way to Die",
      "I Need You",
      "Samsonite Man",
      "The Thing About Love",
      "Trouble",
      "Fallin' (Remix)",
      "If I Was Your Woman",
      "Wild Horses",
      "Power",
      "Goodbye",
      "Wasted Energy",
    ],
  },
  {
    name: "Bon Jovi",
    songs: [
      "Livin' on a Prayer",
      "You Give Love a Bad Name",
      "It's My Life",
      "Wanted Dead or Alive",
      "Always",
      "Bad Medicine",
      "I'll Be There for You",
      "Runaway",
      "Bed of Roses",
      "Born to Be My Baby",
      "Keep the Faith",
      "Have a Nice Day",
      "In These Arms",
      "Lay Your Hands on Me",
      "Blaze of Glory",
      "This Ain't a Love Song",
      "Someday I'll Be Saturday Night",
      "We Weren't Born to Follow",
      "Who Says You Can't Go Home",
      "These Days",
      "Never Say Goodbye",
      "Lost Highway",
      "Raise Your Hands",
      "Dry County",
      "Undivided",
      "Wild in the Streets",
      "Without Love",
      "Lie to Me",
      "Just Older",
      "Tokyo Road",
      "I'll Sleep When I'm Dead",
      "I'd Die for You",
      "Blood on Blood",
      "Something for the Pain",
      "Diamond Ring",
      "King of the Mountain",
      "Story of My Life",
      "Midnight in Chelsea",
      "She Don't Know Me",
      "Real Life",
      "We All Sleep Alone",
      "If I Was Your Mother",
      "Edge of a Broken Heart",
      "One Wild Night",
      "Burning for Love",
      "99 in the Shade",
      "Silent Night",
      "Only Lonely",
      "Joey",
      "Wildflower",
    ],
  },
  {
    name: "Nas",
    songs: [
      "N.Y. State of Mind",
      "If I Ruled the World (Imagine That)",
      "The World Is Yours",
      "Nas Is Like",
      "One Mic",
      "It Ain't Hard to Tell",
      "Hate Me Now",
      "Made You Look",
      "I Can",
      "The Message",
      "Life's a B****",
      "Street Dreams",
      "Got Ur Self A...",
      "Ether",
      "Represent",
      "Halftime",
      "Memory Lane (Sittin' in da Park)",
      "No Idea's Original",
      "You're da Man",
      "Get Down",
      "Thief's Theme",
      "One Love",
      "Just a Moment",
      "The Don",
      "Hip Hop Is Dead",
      "The Cross",
      "Doo Rags",
      "Rewind",
      "Purple",
      "Surviving the Times",
      "Project Windows",
      "2nd Childhood",
      "Blaze a 50",
      "Nas Angels...The Flyest",
      "War",
      "A Queens Story",
      "Bye Baby",
      "Stay",
      "Black Republican",
      "Where Are They Now",
      "Get Down (Live)",
      "Heaven",
      "You're the Man",
      "Revolutionary Warfare",
      "Get Down (Remix)",
      "The World Is Yours (Remix)",
      "Nas Is Like (Remix)",
      "One Mic (Remix)",
      "It Ain't Hard to Tell (Remix)",
      "If I Ruled the World (Remix)",
    ],
  },
  {
    name: "The Libertines",
    songs: [
      "Can't Stand Me Now",
      "Don't Look Back into the Sun",
      "Time for Heroes",
      "What Katie Did",
      "Music When the Lights Go Out",
      "Up the Bracket",
      "What Became of the Likely Lads",
      "Boys in the Band",
      "The Good Old Days",
      "Death on the Stairs",
      "I Get Along",
      "Vertigo",
      "The Delaney",
      "Campaign of Hate",
      "Horrorshow",
      "The Man Who Would Be King",
      "Last Post on the Bugle",
      "Skag & Bone Man",
      "Arbeit Macht Frei",
      "Radio America",
      "Tell the King",
      "The Ha Ha Wall",
      "Road to Ruin",
      "Love on the Dole",
      "Heart of the Matter",
      "Barbarians",
      "You're My Waterloo",
      "Anthem for Doomed Youth",
      "Gunga Din",
      "Glasgow Coma Scale Blues",
      "Iceman",
      "Bucket Shop",
      "Handsome",
      "Dead for Love",
      "The Milkman's Horse",
      "Belly of the Beast",
      "Fame and Fortune",
      "The Saga",
      "The Boy Looked at Johnny",
      "France",
      "Seven Deadly Sins",
      "General Smuts",
      "Bangkok",
      "Sticks and Stones",
      "Mocking Bird",
      "General Smuts II",
      "Bound Together",
      "Hooray",
      "Serpentines",
      "Lust of the Libertines",
    ],
  },
  {
    name: "Billy Idol",
    songs: [
      "Rebel Yell",
      "White Wedding",
      "Dancing with Myself",
      "Eyes Without a Face",
      "Flesh for Fantasy",
      "Mony Mony",
      "Cradle of Love",
      "Hot in the City",
      "Sweet Sixteen",
      "To Be a Lover",
      "Don't Need a Gun",
      "Catch My Fall",
      "L.A. Woman",
      "Scream",
      "Shock to the System",
      "World Comin' Down",
      "Speed",
      "Heroin",
      "Adam in Chains",
      "John Wayne",
      "Love Unchained",
      "Yellin' at the Xmas Tree",
      "Wasteland",
      "Can't Break Me Down",
      "One Breath Away",
      "Nothing to Fear",
      "Rat Race",
      "Blue Highway",
      "Dead on Arrival",
      "Man for All Seasons",
      "The Loveless",
      "New Future Weapon",
      "Body Snatcher",
      "Romeo's Waiting",
      "Don't Need a Gun (Live)",
      "Hot in the City (Live)",
      "L.A. Woman (Live)",
      "World's Forgotten Boy (Live)",
      "Flesh for Fantasy (Live)",
      "White Wedding (Live)",
      "Rebel Yell (Live)",
      "Dancing with Myself (Live)",
      "Mony Mony (Live)",
      "Cradle of Love (Live)",
      "Sweet Sixteen (Live)",
      "Eyes Without a Face (Live)",
      "Heroin (Live)",
      "Shock to the System (Live)",
      "Catch My Fall (Live)",
    ],
  },
  {
    name: "Pearl Jam",
    songs: [
      "Alive",
      "Jeremy",
      "Black",
      "Even Flow",
      "Better Man",
      "Daughter",
      "Corduroy",
      "Rearviewmirror",
      "Given to Fly",
      "Elderly Woman Behind the Counter in a Small Town",
      "Yellow Ledbetter",
      "Do the Evolution",
      "Animal",
      "Wishlist",
      "Nothingman",
      "Last Kiss",
      "Hail, Hail",
      "Sirens",
      "I Am Mine",
      "Immortality",
      "Go",
      "Amongst the Waves",
      "Oceans",
      "State of Love and Trust",
      "Release",
      "In Hiding",
      "Brain of J.",
      "Garden",
      "Who You Are",
      "Leash",
      "Why Go",
      "Just Breathe",
      "Love Boat Captain",
      "Indifference",
      "Off He Goes",
      "Save You",
      "Breakerfall",
      "Light Years",
      "World Wide Suicide",
      "Come Back",
    ],
  },
  {
    name: "Van Halen",
    songs: [
      "Jump",
      "Panama",
      "Hot for Teacher",
      "Runnin' with the Devil",
      "Eruption",
      "Ain't Talkin' 'Bout Love",
      "Dance the Night Away",
      "Right Now",
      "Why Can't This Be Love",
      "And the Cradle Will Rock...",
      "Dreams",
      "You Really Got Me",
      "Unchained",
      "I'll Wait",
      "Beautiful Girls",
      "Jamie's Cryin'",
      "Best of Both Worlds",
      "Love Walks In",
      "When It's Love",
      "Finish What Ya Started",
      "Feel Your Love Tonight",
      "Everybody Wants Some!!",
      "Mean Street",
      "Little Guitars",
      "Ice Cream Man",
      "So This Is Love?",
      "Atomic Punk",
      "Summer Nights",
      "Don't Tell Me (What Love Can Do)",
      "Poundcake",
      "Runaround",
      "Humans Being",
      "Top of the World",
      "5150",
      "Cabo Wabo",
      "Mine All Mine",
      "Seventh Seal",
      "Amsterdam",
      "Aftershock",
      "Black and Blue",
      "The Full Bug",
      "Secrets",
      "Hang 'Em High",
      "Cathedral",
      "Intruder",
      "House of Pain",
      "Fools",
      "In 'N' Out",
      "Man on a Mission",
      "Crossing Over",
    ],
  },
  {
    name: "Spandau Ballet",
    songs: [
      "True",
      "Gold",
      "Through the Barricades",
      "Only When You Leave",
      "To Cut a Long Story Short",
      "Chant No. 1 (I Don't Need This Pressure On)",
      "Lifeline",
      "Communication",
      "I'll Fly for You",
      "Round and Round",
      "Musclebound",
      "Instinction",
      "Highly Strung",
      "Paint Me Down",
      "The Freeze",
      "She Loved Like Diamond",
      "Raw",
      "Be Free with Your Love",
      "With the Pride",
      "Heaven Is a Secret",
      "Foundation",
      "Glow",
      "Fight for Ourselves",
      "Crashed into Love",
      "How Many Lies?",
      'Only When You Leave (12" Version)',
      "Swept",
      "Cross the Line",
      "A Handful of Dust",
      "Code of Love",
      "Man in Chains",
      "Nature of the Beast",
      "Empty Spaces",
      "Pharaoh",
      "Revenge for Love",
      "I'll Fly for You (Glide Mix)",
      "Confused",
      "Missionary",
      "Pleasure",
      "Toys",
      'Glow (7" Version)',
      'Musclebound (12" Version)',
      "True (US Remix)",
      'She Loved Like Diamond (12" Version)',
      "Raw (Amnesia Mix)",
      "Gold (Extended Version)",
      "With the Pride (Live)",
      "Through the Barricades (Extended Version)",
      "Lifeline (Extended Version)",
    ],
  },
  {
    name: "NSYNC",
    songs: [
      "Bye Bye Bye",
      "It's Gonna Be Me",
      "This I Promise You",
      "Tearin' Up My Heart",
      "Pop",
      "I Want You Back",
      "God Must Have Spent a Little More Time on You",
      "Girlfriend",
      "Gone",
      "I'll Never Stop",
      "Music of My Heart",
      "For the Girl Who Has Everything",
      "I Drive Myself Crazy",
      "U Drive Me Crazy",
      "No Strings Attached",
      "Digital Get Down",
      "Space Cowboy (Yippie-Yi-Yay)",
      "Up Against the Wall",
      "It Makes Me Ill",
      "Just Got Paid",
      "The Two of Us",
      "Bringin' Da Noise",
      "Giddy Up",
      "Celebrity",
      "Do Your Thing",
      "See Right Through You",
      "Selfish",
      "Tell Me, Tell Me...Baby",
      "Gone (Clubbin')",
      "The Game Is Over",
      "Just Don't Tell Me That",
      "Something Like You",
      "That Girl (Will Never Be Mine)",
      "Falling",
      "Here We Go",
      "I Thought She Knew",
      "If I'm Not the One",
      "Crazy for You",
      "I Need Love",
      "If Only in Heaven's Eyes",
      "Somewhere, Someday",
      "Forever Young",
      "Sailing",
      "Thinking of You (I Drive Myself Crazy)",
      "Everything I Own",
      "Bye Bye Bye (Teddy Riley Remix)",
      "The Lion Sleeps Tonight",
      "Sundreams",
      "Under My Tree",
      "Merry Christmas, Happy Holidays",
    ],
  },
  {
    name: "Pet Shop Boys",
    songs: [
      "West End Girls",
      "It's a Sin",
      "Always on My Mind",
      "Go West",
      "Opportunities (Let's Make Lots of Money)",
      "Domino Dancing",
      "Suburbia",
      "What Have I Done to Deserve This?",
      "Left to My Own Devices",
      "New York City Boy",
      "Rent",
      "Love Comes Quickly",
      "Jealousy",
      "Heart",
      "Can You Forgive Her?",
      "Being Boring",
      "So Hard",
      "Se a vida é (That's the Way Life Is)",
      "Where the Streets Have No Name (I Can't Take My Eyes Off You)",
      "I Don't Know What You Want but I Can't Give It Any More",
      "Paninaro",
      "Liberation",
      "Before",
      "Miracles",
      "A Red Letter Day",
      "Single-Bilingual",
      "Was It Worth It?",
      "Minimal",
      "Flamboyant",
      "Did You See Me Coming?",
      "Together",
      "Leaving",
      "Home and Dry",
      "I Get Along",
      "Integral",
      "Thursday",
      "Love etc.",
      "Winner",
      "Say It to Me",
      "Twenty-something",
      "The Pop Kids",
      "Axis",
      "Burn",
      "In the Night",
      "DJ Culture",
      "How Can You Expect to Be Taken Seriously?",
      "Yesterday, When I Was Mad",
      "Bilingual",
      "I Wouldn't Normally Do This Kind of Thing",
      "Somewhere",
      "A Man Could Get Arrested",
    ],
  },
  {
    name: "Snow Patrol",
    songs: [
      "Chasing Cars",
      "Run",
      "Just Say Yes",
      "Called Out in the Dark",
      "Open Your Eyes",
      "Shut Your Eyes",
      "You're All I Have",
      "Signal Fire",
      "Take Back the City",
      "Crack the Shutters",
      "In the End",
      "Set the Fire to the Third Bar",
      "This Isn't Everything You Are",
      "If There's a Rocket Tie Me to It",
      "Don't Give In",
      "New York",
      "Life on Earth",
      "Dark Roman Wine",
      "The Lightning Strike",
      "Hands Open",
      "How to Be Dead",
      "Chocolate",
      "Grazed Knees",
      "Make This Go On Forever",
      "It's Beginning to Get to Me",
      "The Planets Bend Between Us",
      "Please Just Take These Photos from My Hands",
      "Headlights on Dark Roads",
      "Warmer Climate",
      "You Could Be Happy",
      "Spitting Games",
      "An Olive Grove Facing the Sea",
      "Tiny Little Fractures",
      "Somewhere a Clock Is Ticking",
      "Ways & Means",
      "One Night Is Not Enough",
      "Soon",
      "The Golden Floor",
      "Engines",
      "Lifening",
    ],
  },
  {
    name: "Christina Aguilera",
    songs: [
      "Genie in a Bottle",
      "Beautiful",
      "Fighter",
      "Dirrty",
      "Candyman",
      "Ain't No Other Man",
      "What a Girl Wants",
      "Hurt",
      "Come On Over (All I Want Is You)",
      "Lady Marmalade",
      "I Turn to You",
      "Your Body",
      "The Voice Within",
      "Can't Hold Us Down",
      "Not Myself Tonight",
      "Keeps Gettin' Better",
      "Car Wash",
      "You Lost Me",
      "Reflection",
      "Walk Away",
      "Pero Me Acuerdo de Ti",
      "Genio Atrapado",
      "Falsas Esperanzas",
      "Mi Reflejo",
      "Somos Novios",
      "Infatuation",
      "Get Mine, Get Yours",
      "Loving Me 4 Me",
      "Soar",
      "Impossible",
      "Underappreciated",
      "Thank You (Dedication to Fans...)",
      "Back in the Day",
      "Without You",
      "Still Dirrty",
      "On Our Way",
      "Shut Up",
      "Sex for Breakfast",
      "Monday Morning",
      "Bobblehead",
      "Birds of Prey",
      "Little Dreamer",
      "I Hate Boys",
      "Prima Donna",
      "All I Need",
      "My Heart",
      "Best of Me",
      "Dreamer",
      "Stronger Than Ever",
      "Desnúdate",
    ],
  },
  {
    name: "OutKast",
    songs: [
      "Ms. Jackson",
      "Hey Ya!",
      "So Fresh, So Clean",
      "B.O.B (Bombs Over Baghdad)",
      "Roses",
      "ATLiens",
      "Rosa Parks",
      "Elevators (Me & You)",
      "The Way You Move",
      "SpottieOttieDopaliscious",
      "Prototype",
      "Player's Ball",
      "Skew It on the Bar-B",
      "Aquemini",
      "Git Up, Git Out",
      "Crumblin' Erb",
      "Southernplayalisticadillacmuzik",
      "Da Art of Storytellin' (Pt. 1)",
      "Chonkyfire",
      "Return of the 'G'",
      "Slump",
      "Da Art of Storytellin' (Pt. 2)",
      "Humble Mumble",
      "Funky Ride",
      "Ain't No Thang",
      "West Savannah",
      "Mamacita",
      "D.E.E.P.",
      "Two Dope Boyz (In a Cadillac)",
      "Babylon",
      "Hollywood Divorce",
      "GhettoMusick",
      "She Lives in My Lap",
      "We Luv Deez Hoez",
      "Happy Valentine's Day",
      "Benz or Beamer",
      "Jazzy Belle",
      "Gangsta Shit",
      "Y'all Scared",
      "Crumblin' Herb",
      "Pink & Blue",
      "Myintrotoletuknow",
      "14 Aliens",
      "So Fresh, So Clean (Remix)",
      "Slump (Remix)",
      "Two Dope Boyz (In a Cadillac) (Remix)",
      "Funky Ride (Remix)",
      "Ain't No Thang (Remix)",
      "West Savannah (Remix)",
      "Hollywood Divorce (Remix)",
    ],
  },
  {
    name: "Shakira",
    songs: [
      "Hips Don't Lie",
      "Whenever, Wherever",
      "Waka Waka (This Time for Africa)",
      "She Wolf",
      "Underneath Your Clothes",
      "La Tortura",
      "Try Everything",
      "Beautiful Liar",
      "Chantaje",
      "Objection (Tango)",
      "Can't Remember to Forget You",
      "Loca",
      "Empire",
      "Dare (La La La)",
      "Ojos Así",
      "Me Enamoré",
      "Ciega, Sordomuda",
      "Las de la Intuición",
      "Sale el Sol",
      "Addicted to You",
      "No",
      "Tu",
      "Gypsy",
      "Did It Again",
      "Men in This Town",
      "Poem to a Horse",
      "Inevitable",
      "Antología",
      "Moscas en la Casa",
      "Donde Estás Corazón?",
      "Si Te Vas",
      "Lo Que Más",
      "Sombra de Ti",
      "Estoy Aquí",
      "Que Me Quedes Tú",
      "Te Dejo Madrid",
      "Suerte (Whenever, Wherever)",
      "Pies Descalzos, Sueños Blancos",
      "Dia de Enero",
      "No Creo",
      "Te Aviso, Te Anuncio (Tango)",
      "La Pared",
      "En Tus Pupilas",
      "Devoción",
      "Fool",
      "Animal City",
      "Timor",
      "Tu Boca",
      "Octavo Día",
      "Mariposas",
    ],
  },
  {
    name: "Pink",
    songs: [
      "Just Give Me a Reason",
      "So What",
      "Try",
      "Just Like a Pill",
      "Raise Your Glass",
      "Blow Me (One Last Kiss)",
      "Sober",
      "Who Knew",
      "What About Us",
      "Please Don't Leave Me",
      "Family Portrait",
      "Don't Let Me Get Me",
      "U + Ur Hand",
      "Glitter in the Air",
      "F**kin' Perfect",
      "Get the Party Started",
      "Funhouse",
      "Stupid Girls",
      "There You Go",
      "Most Girls",
      "Walk Me Home",
      "True Love",
      "I Don't Believe You",
      "Whatever You Want",
      "Bad Influence",
      "Trouble",
      "Dear Mr. President",
      "Beam Me Up",
      "Runaway",
      "The One That Got Away",
      "Cuz I Can",
      "God Is a DJ",
      "Heartbreak Down",
      "I Am Here",
      "The Great Escape",
      "But We Lost It",
      "90 Days",
      "Barbies",
      "You Get My Love",
      "Just Like Fire",
      "Today's the Day",
      "Setting the World on Fire",
      "Beautiful Trauma",
      "Revenge",
      "Secrets",
      "Where We Go",
      "I Am Here",
      "Better Life",
      "Wild Hearts Can't Be Broken",
      "For Now",
    ],
  },
  {
    name: "Franz Ferdinand",
    songs: [
      "Take Me Out",
      "Do You Want To",
      "No You Girls",
      "The Dark of the Matinée",
      "Michael",
      "This Fire",
      "Walk Away",
      "Ulysses",
      "Lucid Dreams",
      "Can't Stop Feeling",
      "Right Action",
      "Evil Eye",
      "Love Illumination",
      "Stand on the Horizon",
      "Bullet",
      "Darts of Pleasure",
      "Outsiders",
      "You're the Reason I'm Leaving",
      "Matinee",
      "What She Came For",
      "Fresh Strawberries",
      "Goodbye Lovers & Friends",
      "Lazy Boy",
      "Paper Cages",
      "Finally",
      "Glimpse of Love",
      "Always Ascending",
      "Come on Home",
      "Send Him Away",
      "Turn It On",
      "The Fallen",
      "Twilight Omens",
      "What Time to Waste",
      "Cheating on You",
      "Live Alone",
      "Bite Hard",
      "Well That Was Easy",
      "40'",
      "I'm Your Villain",
      "Outsiders",
    ],
  },
  {
    name: "Cypress Hill",
    songs: [
      "Insane in the Brain",
      "Hits from the Bong",
      "How I Could Just Kill a Man",
      "I Ain't Goin' Out Like That",
      "Rock Superstar",
      "Tequila Sunrise",
      "Hand on the Pump",
      "Throw Your Set in the Air",
      "Latin Lingo",
      "When the Sh-- Goes Down",
      "Dr. Greenthumb",
      "Boom Biddy Bye Bye",
      "Pigs",
      "Illusions",
      "Real Estate",
      "Stoned Is the Way of the Walk",
      "Funk Freakers",
      "No Rest for the Wicked",
      "The Phuncky Feel One",
      "Stoned Raiders",
      "Looking Through the Eye of a Pig",
      "Killafornia",
      "What Go Around Come Around, Kid",
      "Strictly Hip Hop",
      "Locotes",
      "Everybody Must Get Stoned",
      "Busted in the Hood",
      "No Entiendes La Onda",
      "Make a Move",
      "Light It Up",
      "Break 'Em Off Some",
      "We Ain't Goin' Out Like That (Remix)",
      "Throw Your Set in the Air (Remix)",
      "Latin Lingo (Remix)",
      "Hand on the Pump (Remix)",
      "Hits from the Bong (Remix)",
      "How I Could Just Kill a Man (Remix)",
      "Rock Superstar (Remix)",
      "Illusions (Remix)",
      "When the Sh-- Goes Down (Remix)",
      "Tequila Sunrise (Remix)",
      "Boom Biddy Bye Bye (Remix)",
      "Pigs (Remix)",
      "Funk Freakers (Remix)",
      "No Rest for the Wicked (Remix)",
      "The Phuncky Feel One (Remix)",
      "Stoned Raiders (Remix)",
      "Looking Through the Eye of a Pig (Remix)",
      "Killafornia (Remix)",
    ],
  },
  {
    name: "Smashing Pumpkins",
    songs: [
      "1979",
      "Bullet with Butterfly Wings",
      "Tonight, Tonight",
      "Today",
      "Disarm",
      "Cherub Rock",
      "Zero",
      "Ava Adore",
      "Stand Inside Your Love",
      "Mayonaise",
      "Perfect",
      "Rocket",
      "Landslide",
      "Thirty-Three",
      "Muzzle",
      "Rhinoceros",
      "Siva",
      "Eye",
      "Drown",
      "The Everlasting Gaze",
      "Try, Try, Try",
      "Tarantula",
      "G.L.O.W.",
      "Freak",
      "Panopticon",
      "Tristessa",
      "Blew Away",
      "I Am One",
      "To Sheila",
      "For Martha",
      "The End Is the Beginning Is the End",
      "Untitled",
      "Bring the Light",
      "Being Beige",
      "Pale Horse",
      "Soot and Stars",
      "An Ode to No One",
      "To Forgive",
      "Geek U.S.A.",
      "Bodies",
    ],
  },
  {
    name: "Simple Minds",
    songs: [
      "Don't You (Forget About Me)",
      "Alive and Kicking",
      "Belfast Child",
      "Promised You a Miracle",
      "Waterfront",
      "Sanctify Yourself",
      "Love Song",
      "All the Things She Said",
      "Glittering Prize",
      "Someone Somewhere in Summertime",
      "See the Lights",
      "Up on the Catwalk",
      "New Gold Dream (81-82-83-84)",
      "Ghost Dancing",
      "Mandela Day",
      "She's a River",
      "Speed Your Love to Me",
      "Let There Be Love",
      "This Is Your Land",
      "Hypnotised",
      "Real Life",
      "Stand by Love",
      "The American",
      "Sweat in Bullet",
      "Life in a Day",
      "Chelsea Girl",
      "I Travel",
      "Celebrate",
      "Someone",
      "Theme for Great Cities",
      "The Kick Inside of Me",
      "Big Sleep",
      "Book of Brilliant Things",
      "Hunter and the Hunted",
      "War Babies",
      "Dolphins",
      "Moscow Underground",
      "Honest Town",
      "Big Music",
      "Broken Glass Park",
      "Blindfolded",
      "Midnight Walking",
      "Blood Diamonds",
      "Let the Day Begin",
      "Kiss the Ground",
      "Broken Dreams",
      "Mandela Day (Live)",
      "Ghost Dancing (Live)",
      "Waterfront (Live)",
      "Glittering Prize (Live)",
      "Promised You a Miracle (Live)",
    ],
  },
  {
    name: "Goo Goo Dolls",
    songs: [
      "Iris",
      "Slide",
      "Name",
      "Black Balloon",
      "Broadway",
      "Better Days",
      "Here Is Gone",
      "Sympathy",
      "Big Machine",
      "Let Love In",
      "Stay with You",
      "Come to Me",
      "Give a Little Bit",
      "Rebel Beat",
      "So Alive",
      "Long Way Down",
      "Dizzy",
      "Before It's Too Late (Sam and Mikaela's Theme)",
      "Over and Over",
      "Without You Here",
      "All That You Are",
      "Real",
      "Notbroken",
      "Home",
      "January Friend",
      "Amigone",
      "Hate This Place",
      "We'll Be Here (When You're Gone)",
      "Sweetest Lie",
      "You Should Be Happy",
      "Now I Hear",
      "Nothing Is Real",
      "Lucky Star",
      "Acoustic #3",
      "Think About Me",
      "Burnin' Up",
      "Truth Is a Whisper",
      "What a Scene",
      "Slide (Acoustic)",
      "Two Days in February",
      "Stop the World",
      "Hey Ya",
      "Feel the Silence",
      "Bullet Proof",
      "All Eyes on Me",
      "Smash",
      "I Wanna Destroy You",
      "Without a Trace",
      "Fallin' Down",
      "There You Are",
    ],
  },
  {
    name: "Spin Doctors",
    songs: [
      "Two Princes",
      "Little Miss Can't Be Wrong",
      "Jimmy Olsen's Blues",
      "What Time Is It?",
      "How Could You Want Him (When You Know You Could Have Me?)",
      "Cleopatra's Cat",
      "You've Got to Believe in Something",
      "Mary Jane",
      "Big Fat Funky Booty",
      "More Than She Knows",
      "Forty or Fifty",
      "Refrigerator Car",
      "Off My Line",
      "Biscuit Head",
      "Laraby's Gang",
      "Genuine",
      "Scotch and Water Blues",
      "Nice Talking to Me",
      "Traction Blues",
      "Hungry Hamed's",
      "House",
      "Some Other Man Instead",
      "Pocket Full of Kryptonite",
      "Freeway of the Plains",
      "Can't Kick the Habit",
      "If the River Was Whiskey",
      "Sweetest Portion",
      "Sugar",
      "Margarita",
      "Big Fat Funky Booty (Live)",
      "Freeway of the Plains (Live)",
      "Little Miss Can't Be Wrong (Live)",
      "Cleopatra's Cat (Live)",
      "More Than Meets the Ear",
      "Gorilla Boy",
      "Yo Mama's a Pajama",
      "Turn It Upside Down",
      "Big Fat Funky Booty (Studio)",
      "Shinbone Alley",
      "Indifference",
      "Freeway of the Plains (Studio)",
      "At This Hour",
      "Blind Man",
      "Mary Jane (Studio)",
      "Yo Mama's a Pajama (Studio)",
      "At This Hour (Studio)",
      "Rosetta Stone",
      "The Drop",
      "House (Live)",
      "Some Other Man Instead (Live)",
    ],
  },
  {
    name: "Bone Thugs-n-Harmony",
    songs: [
      "Tha Crossroads",
      "1st of tha Month",
      "Thuggish Ruggish Bone",
      "Foe tha Love of $",
      "East 1999",
      "Resurrection (Paper, Paper)",
      "Look into My Eyes",
      "Days of Our Livez",
      "Home",
      "Change the World",
      "If I Could Teach the World",
      "For the Love of Money",
      "I Tried",
      "Mo' Murda",
      "Weed Song",
      "Shoot 'Em Up",
      "Thug Luv",
      "Creepin' on ah Come Up",
      "No Surrender",
      "Ghetto Cowboy",
      "Body Rott",
      "Eternal",
      "Friends",
      "Mind of a Souljah",
      "Down '71 (The Getaway)",
      "Everything You See",
      "Buddah Lovaz",
      "No Shorts, No Losses",
      "Crossroad",
      "Get Up & Get It",
      "BNK",
      "The Righteous Ones",
      "Can't Give It Up",
      "See Me Shine",
      "My Life",
      "Everyday Thugs",
      "Meet Me in the Sky",
      "Ghetto Glory",
      "Rebirth",
      "A Thugga' Level",
      "So Good So Right",
      "Candy Paint",
      "Bone Bone Bone",
      "Remember Yesterday",
      "Tha Crossroads (Remix)",
      "1st of tha Month (Remix)",
      "Thuggish Ruggish Bone (Remix)",
      "Foe tha Love of $ (Remix)",
      "East 1999 (Remix)",
      "Resurrection (Paper, Paper) (Remix)",
    ],
  },
  {
    name: "Wham!",
    songs: [
      "Wake Me Up Before You Go-Go",
      "Last Christmas",
      "Everything She Wants",
      "Careless Whisper",
      "Club Tropicana",
      "Freedom",
      "I'm Your Man",
      "Wham Rap! (Enjoy What You Do)",
      "Young Guns (Go for It)",
      "Edge of Heaven",
      "Bad Boys",
      "A Different Corner",
      "Where Did Your Heart Go?",
      "Heartbeat",
      "If You Were There",
      "Blue (Armed with Love)",
      "A Ray of Sunshine",
      "Like a Baby",
      "Credit Card Baby",
      "Come On!",
      "The Boys Are Back in Town",
      "Battlestations",
      "Where Did Your Heart Go? (Remix)",
      "Everything She Wants (Remix)",
      "Blue (Live in China)",
      "A Different Corner (Instrumental)",
      "Wake Me Up Before You Go-Go (Instrumental)",
      'Young Guns (Go for It) (12" Mix)',
      'Wham Rap! (Enjoy What You Do) (12" Mix)',
      'Bad Boys (12" Version)',
      'Club Tropicana (12" Mix)',
      "Freedom (Long Mix)",
      "Last Christmas (Pudding Mix)",
      "Everything She Wants (Remix)",
      "I'm Your Man (Extended Stimulation)",
      "Last Christmas (Instrumental)",
      "Where Did Your Heart Go? (Instrumental)",
      "Club Fantastic Megamix",
      "Blue (Live in China) (Instrumental)",
      "Wake Me Up Before You Go-Go (Live)",
      "Young Guns (Go for It) (Live)",
      "Wham Rap! (Enjoy What You Do) (Live)",
      "Bad Boys (Live)",
      "Club Tropicana (Live)",
      "Freedom (Live)",
      "Last Christmas (Live)",
      "Everything She Wants (Live)",
      "I'm Your Man (Live)",
      "Club Fantastic Megamix (Live)",
      "Blue (Live)",
    ],
  },
  {
    name: "Kaiser Chiefs",
    songs: [
      "Ruby",
      "I Predict a Riot",
      "Oh My God",
      "Never Miss a Beat",
      "Everyday I Love You Less and Less",
      "Modern Way",
      "The Angry Mob",
      "Coming Home",
      "Everything Is Average Nowadays",
      "Na Na Na Na Naa",
      "Good Days Bad Days",
      "Love's Not a Competition (But I'm Winning)",
      "My Life",
      "Heat Dies Down",
      "You Can Have It All",
      "Cannons",
      "My Kind of Guy",
      "Golden Skans",
      "Team Mate",
      "Sunday Morning",
      "Born to Be a Dancer",
      "Caroline, Yes",
      "Like It Too Much",
      "Half the Truth",
      "Little Shocks",
      "On the Run",
      "Man on Mars",
      "Bows & Arrows",
      "Meanwhile Up in Heaven",
      "One More Last Song",
      "Misery Company",
      "Hole in My Soul",
      "Parachute",
      "Falling Awake",
      "Still Waiting",
      "Dead or in Serious Trouble",
      "Good Clean Fun",
      "Press Rewind",
      "Record Collection",
      "Learnt My Lesson Well",
    ],
  },
  {
    name: "The White Stripes",
    songs: [
      "Seven Nation Army",
      "Fell in Love with a Girl",
      "Icky Thump",
      "We're Going to Be Friends",
      "Hotel Yorba",
      "Dead Leaves and the Dirty Ground",
      "Blue Orchid",
      "My Doorbell",
      "The Hardest Button to Button",
      "Jolene",
      "You Don't Know What Love Is (You Just Do as You're Told)",
      "Apple Blossom",
      "Ball and Biscuit",
      "I Just Don't Know What to Do with Myself",
      "The Denial Twist",
      "Conquest",
      "There's No Home for You Here",
      "I'm Slowly Turning Into You",
      "300 M.P.H. Torrential Outpour Blues",
      "Little Cream Soda",
      "A Martyr for My Love for You",
      "Effect and Cause",
      "Forever for Her (Is Over for Me)",
      "Hypnotize",
      "Cannon",
      "Astro",
      "Screwdriver",
      "Do",
      "I'm Finding It Harder to Be a Gentleman",
      "Now Mary",
      "Offend in Every Way",
      "The Union Forever",
      "The Air Near My Fingers",
      "Girl, You Have No Faith in Medicine",
      "Rag and Bone",
      "Catch Hell Blues",
      "Sugar Never Tasted So Good",
      "Broken Bricks",
      "Let's Build a Home",
      "Little Room",
    ],
  },
  {
    name: "Def Leppard",
    songs: [
      "Pour Some Sugar on Me",
      "Love Bites",
      "Hysteria",
      "Photograph",
      "Rock of Ages",
      "Armageddon It",
      "Animal",
      "Bringin' On the Heartbreak",
      "Foolin'",
      "Rocket",
      "Let's Get Rocked",
      "Two Steps Behind",
      "When Love and Hate Collide",
      "Have You Ever Needed Someone So Bad",
      "Women",
      "High 'n' Dry (Saturday Night)",
      "Too Late for Love",
      "Promises",
      "Heaven Is",
      "Rock! Rock! (Till You Drop)",
      "Let It Go",
      "Stand Up (Kick Love into Motion)",
      "Gods of War",
      "Billy's Got a Gun",
      "Slang",
      "Miss You in a Heartbeat",
      "Desert Song",
      "Make Love Like a Man",
      "Excitable",
      "All I Want Is Everything",
      "Rock Brigade",
      "Work It Out",
      "Goodbye",
      "Day After Day",
      "Paper Sun",
      "Guilty",
      "Tonight",
      "Nine Lives",
      "Bad Actress",
      "Undefeated",
      "It's All About Believin'",
      "Waterloo Sunset",
      "Go",
      "Come Undone",
      "Kings of the World",
      "Dangerous",
      "Let's Go",
      "Invincible",
      "Man Enough",
      "Energized",
    ],
  },
  {
    name: "Journey",
    songs: [
      "Don't Stop Believin'",
      "Faithfully",
      "Any Way You Want It",
      "Open Arms",
      "Separate Ways (Worlds Apart)",
      "Wheel in the Sky",
      "Lovin', Touchin', Squeezin'",
      "Lights",
      "Who's Crying Now",
      "Send Her My Love",
      "Only the Young",
      "Stone in Love",
      "Be Good to Yourself",
      "Girl Can't Help It",
      "I'll Be Alright Without You",
      "Ask the Lonely",
      "Escape",
      "Still They Ride",
      "After the Fall",
      "Mother, Father",
      "Why Can't This Night Go on Forever",
      "When You Love a Woman",
      "Feeling That Way",
      "Just the Same Way",
      "The Party's Over (Hopelessly in Love)",
      "Walks Like a Lady",
      "Good Morning Girl",
      "Where Were You",
      "Stay Awhile",
      "City of the Angels",
      "Line of Fire",
      "Message of Love",
      "Castles Burning",
      "With Your Love",
      "I Can Breathe",
      "Butterfly (She Flies Alone)",
      "Edge of the Blade",
      "All That Really Matters",
      "La Do Da",
      "Natural Thing",
      "Dixie Highway",
      "Troubled Child",
      "People and Places",
      "Patiently",
      "To Play Some Music",
      "Of a Lifetime",
      "Something to Hide",
      "Someday Soon",
      "When the Summer's Gone",
    ],
  },
  {
    name: "Destiny's Child",
    songs: [
      "Say My Name",
      "Survivor",
      "Independent Women, Pt. 1",
      "Bills, Bills, Bills",
      "Bootylicious",
      "Jumpin', Jumpin'",
      "No, No, No Part 2",
      "Lose My Breath",
      "Bug a Boo",
      "Cater 2 U",
      "Emotion",
      "Soldier",
      "Girl",
      "Nasty Girl",
      "With Me Part I",
      "Temptation",
      "Get on the Bus",
      "Now That She's Gone",
      "Happy Face",
      "So Good",
      "Brown Eyes",
      "Love",
      "Game Over",
      "Fancy",
      "If",
      "Nuclear",
      "Free",
      "Through with Love",
      "Is She the Reason",
      "Love/Hate",
      "T-Shirt",
      "Bad Habit",
      "Feel the Same Way I Do",
      "She Can't Love You",
      "Apple Pie à la Mode",
      "Sweet Sixteen",
      "My Heart Still Beats",
      "Independent Women, Pt. 2",
      "Bridges",
      "Check on It",
      "Sexy Daddy",
      "Gospel Medley",
      "Outro (Amazing Grace...Dedicated to Andretta Tillman)",
      "8 Days of Christmas",
      "Do You Hear What I Hear",
      "Platinum Bells",
      "Silent Night",
      "Spread a Little Love on Christmas Day",
      "A DC Christmas Medley",
    ],
  },
  {
    name: "Talking Heads",
    songs: [
      "Once in a Lifetime",
      "Psycho Killer",
      "Burning Down the House",
      "This Must Be the Place (Naive Melody)",
      "Road to Nowhere",
      "And She Was",
      "Life During Wartime",
      "Take Me to the River",
      "Wild Wild Life",
      "Heaven",
      "Girlfriend Is Better",
      "Crosseyed and Painless",
      "Born Under Punches (The Heat Goes On)",
      "Slippery People",
      "Houses in Motion",
      "Love → Building on Fire",
      "The Great Curve",
      "Pulled Up",
      "Found a Job",
      "Don't Worry About the Government",
      "Mind",
      "Memories Can't Wait",
      "Stay Up Late",
      "Uh-Oh, Love Comes to Town",
      "Blind",
      "I Zimbra",
      "Swamp",
      "No Compassion",
      "Thank You for Sending Me an Angel",
      "Artists Only",
      "Air",
      "Drugs",
      "Creatures of Love",
      "The Overload",
      "The Good Thing",
      "Big Business",
      "Perfect World",
      "Moon Rocks",
      "Totally Nude",
      "Nothing But Flowers",
    ],
  },
  {
    name: "No Doubt",
    songs: [
      "Don't Speak",
      "Just a Girl",
      "Spiderwebs",
      "Hella Good",
      "It's My Life",
      "Sunday Morning",
      "Underneath It All",
      "Hey Baby",
      "Ex-Girlfriend",
      "Running",
      "Bathwater",
      "Simple Kind of Life",
      "Settle Down",
      "New",
      "Trapped in a Box",
      "Excuse Me Mr.",
      "Too Late",
      "Happy Now?",
      "Different People",
      "Total Hate",
      "End It on This",
      "Six Feet Under",
      "Artificial Sweetener",
      "Magic's in the Makeup",
      "Comforting Lie",
      "Detective",
      "Marry Me",
      "The Climb",
      "Home Now",
      "Rock Steady",
      "Making Out",
      "Greener Pastures",
      "Squeal",
      "Ache",
      "Big City Train",
      "Move On",
      "Staring Problem",
      "Stricken",
      "Doormat",
      "Get on the Ball",
      "Brand New Day",
      "Blue in the Face",
      "Cellophane Boy",
      "Everything in Time (Los Angeles)",
      "Open the Gate",
      "Panic",
      "Everything in Time (London)",
      "Sailin' On",
      "Full Circle",
      "Oi to the World",
    ],
  },
  {
    name: "Wu-Tang Clan",
    songs: [
      "C.R.E.A.M.",
      "Protect Ya Neck",
      "Triumph",
      "Method Man",
      "Da Mystery of Chessboxin'",
      "Wu-Tang Clan Ain't Nuthing ta F' Wit",
      "Gravel Pit",
      "Can It Be All So Simple",
      "Reunited",
      "Bring Da Ruckus",
      "Shame on a N****",
      "Ice Cream",
      "It's Yourz",
      "Uzi (Pinky Ring)",
      "Careful (Click, Click)",
      "Back in the Game",
      "The Heart Gently Weeps",
      "Tearz",
      "Rules",
      "Rushing Elephants",
      "The Projects",
      "Pioneers",
      "Pinky Ring",
      "The Monument",
      "The Worst",
      "Dogs of War",
      "Visions",
      "Chrome Wheels",
      "Older Gods",
      "Redbull",
      "Do You Really (Thang, Thang)",
      "Deadly Melody",
      "Impossible",
      "A Better Tomorrow",
      "Shaolin Worldwide",
      "Soul Power (Black Jungle)",
      "The W",
      "Chamber Music",
      "In the Hood",
      "Ya'll Been Warned",
      "Iron Flag",
      "Careful (Click, Click) (Remix)",
      "Gravel Pit (Remix)",
      "Reunited (Remix)",
      "Protect Ya Neck (The Jump Off)",
      "C.R.E.A.M. (Remix)",
      "Method Man (Remix)",
      "Da Mystery of Chessboxin' (Remix)",
      "Wu-Tang Clan Ain't Nuthing ta F' Wit (Remix)",
    ],
  },
  {
    name: "Creed",
    songs: [
      "With Arms Wide Open",
      "My Sacrifice",
      "Higher",
      "One Last Breath",
      "My Own Prison",
      "What's This Life For",
      "Don't Stop Dancing",
      "Torn",
      "Are You Ready?",
      "Bullets",
      "Weathered",
      "One",
      "Faceless Man",
      "Stand Here with Me",
      "Say I",
      "What If",
      "Ode",
      "Who's Got My Back?",
      "Lullaby",
      "Rain",
      "Freedom Fighter",
      "Beautiful",
      "Hide",
      "Time",
      "On My Sleeve",
      "A Thousand Faces",
      "Suddenly",
      "Overcome",
      "Bread of Shame",
      "Signs",
      "Fear",
      "Unforgiven",
      "Wrong Way",
      "Young Grow Old",
      "Never Die",
      "Wash Away Those Years",
      "Sister",
      "Inside Us All",
      "Illusion",
      "Pity for a Dime",
      "Bound and Tied",
      "In America",
      "Is This the End?",
      "Forever",
      "Six Feet from the Edge",
      "I'm Eighteen",
      "The Song You Sing",
      "The Story of Your Life",
      "To Whom It May Concern",
      "What's This Life For (Acoustic)",
    ],
  },
  {
    name: "Florence + The Machine",
    songs: [
      "Dog Days Are Over",
      "Shake It Out",
      "You've Got the Love",
      "What the Water Gave Me",
      "Spectrum",
      "Cosmic Love",
      "Rabbit Heart (Raise It Up)",
      "Drumming Song",
      "Kiss with a Fist",
      "Hunger",
      "Never Let Me Go",
      "Ship to Wreck",
      "No Light, No Light",
      "Delilah",
      "Queen of Peace",
      "Heavy in Your Arms",
      "Third Eye",
      "St. Jude",
      "Sky Full of Song",
      "How Big, How Blue, How Beautiful",
      "What Kind of Man",
      "Only If for a Night",
      "My Boy Builds Coffins",
      "Blinding",
      "Between Two Lungs",
      "Seven Devils",
      "South London Forever",
      "Big God",
      "Grace",
      "Patricia",
      "June",
      "Moderation",
      "Haunted House",
      "Various Storms & Saints",
      "Which Witch",
      "Mother",
      "Make Up Your Mind",
      "All This and Heaven Too",
      "Lover to Lover",
      "Leave My Body",
      "Breaking Down",
      "Strangeness and Charm",
      "Bedroom Hymns",
      "Landscape",
      "Remain Nameless",
      "Breath of Life",
      "Caught",
      "Too Much Is Never Enough",
      "Wish That You Were Here",
      "Jenny of Oldstones",
    ],
  },
  {
    name: "Sublime",
    songs: [
      "Santeria",
      "What I Got",
      "Wrong Way",
      "Badfish",
      "Doin' Time",
      "April 29, 1992 (Miami)",
      "Smoke Two Joints",
      "Date Rape",
      "Pawn Shop",
      "Garden Grove",
      "Caress Me Down",
      "40oz. to Freedom",
      "Jailhouse",
      "Scarlet Begonias",
      "Same in the End",
      "Don't Push",
      "Let's Go Get Stoned",
      "Burritos",
      "Seed",
      "Greatest-Hits",
      "Saw Red",
      "Under My Voodoo",
      "Boss D.J.",
      "KRS-One",
      "Rivers of Babylon",
      "We're Only Gonna Die for Our Arrogance",
      "Thanx",
      "Get Out!",
      "Hope",
      "Ebin",
      "5446 That's My Number/Ball and Chain",
      "Pool Shark",
      "DJs",
      "April 26, 1992",
      "New Thrash",
      "Chica Me Tipo",
      "Don't Push (Live)",
      "All You Need",
      "Date Rape (Live)",
      "Cisco Kid",
      "I Love My Dog",
      "Badfish (Live)",
      "All You Need (Live)",
      "Lincoln Highway Dub",
      "Mary",
      "Great Stone",
      "House of Suffering",
      "Foolish Fool",
      "Raleigh Soliloquy Pt. III",
    ],
  },
  {
    name: "OneRepublic",
    songs: [
      "Counting Stars",
      "Apologize",
      "Secrets",
      "Stop and Stare",
      "Good Life",
      "All the Right Moves",
      "Love Runs Out",
      "If I Lose Myself",
      "Feel Again",
      "I Lived",
      "Let's Hurt Tonight",
      "Rescue Me",
      "Wherever I Go",
      "Come Home",
      "Something I Need",
      "Future Looks Good",
      "Kids",
      "Burning Bridges",
      "Can't Stop",
      "Au Revoir",
      "No Vacancy",
      "What You Wanted",
      "Preacher",
      "All We Are",
      "Passenger",
      "Light It Up",
      "Marchin On",
      "Dreaming Out Loud",
      "Say (All I Need)",
      "Life in Color",
      "Born to Race",
      "A.I.",
      "Human",
      "Ordinary Human",
      "Let's Hurt Tonight (Film Version)",
      "Colors",
      "Connection",
      "Better",
      "Lift Me Up",
      "Au Revoir (Acoustic)",
      "Bones",
      "Something's Not Right",
      "Champagne Supernova",
      "Last Goodbye",
      "Sucker Punch",
      "Heaven",
      "Stranger Things",
      "Lonely",
      "Wanted",
      "Take Care",
    ],
  },
  {
    name: "The Clash",
    songs: [
      "London Calling",
      "Should I Stay or Should I Go",
      "Rock the Casbah",
      "Train in Vain",
      "I Fought the Law",
      "The Magnificent Seven",
      "Lost in the Supermarket",
      "Clampdown",
      "Rudie Can't Fail",
      "Police on My Back",
      "Complete Control",
      "White Riot",
      "Straight to Hell",
      "Spanish Bombs",
      "Guns of Brixton",
      "Know Your Rights",
      "This Is Radio Clash",
      "White Man in Hammersmith Palais",
      "Safe European Home",
      "Brand New Cadillac",
      "Career Opportunities",
      "Bankrobber",
      "Janie Jones",
      "Garageland",
      "Tommy Gun",
      "Stay Free",
      "Hitsville U.K.",
      "Hateful",
      "Somebody Got Murdered",
      "London's Burning",
      "Death or Glory",
      "Jimmy Jazz",
      "I'm Not Down",
      "The Card Cheat",
      "Protex Blue",
      "Remote Control",
      "Car Jamming",
      "Rebel Waltz",
      "Lover's Rock",
      "Koka Kola",
      "The Call Up",
      "Ghetto Defendant",
      "1-2 Crush on You",
      "Jail Guitar Doors",
      "Up in Heaven (Not Only Here)",
      "The Street Parade",
      "Drug-Stabbing Time",
      "Sean Flynn",
      "Four Horsemen",
      "Broadway",
    ],
  },
  {
    name: "The Stone Roses",
    songs: [
      "I Wanna Be Adored",
      "She Bangs the Drums",
      "Waterfall",
      "Fools Gold",
      "Made of Stone",
      "I Am the Resurrection",
      "Sally Cinnamon",
      "Love Spreads",
      "Elephant Stone",
      "Ten Storey Love Song",
      "This Is the One",
      "Begging You",
      "Mersey Paradise",
      "Standing Here",
      "One Love",
      "Shoot You Down",
      "What the World Is Waiting For",
      "Don't Stop",
      "Sugar Spun Sister",
      "Going Down",
      "(Song for My) Sugar Spun Sister",
      "Bye Bye Badman",
      "Elizabeth My Dear",
      "Your Star Will Shine",
      "All Across the Sands",
      "Where Angels Play",
      "Tightrope",
      "How Do You Sleep",
      "Daybreak",
      "Breaking into Heaven",
      "Driving South",
      "Straight to the Man",
      "Good Times",
      "Tears",
      "Love Spreads (Remix)",
      "Groove Harder",
      "Full Fathom Five",
      "Hardest Thing in the World",
      "Pearl Bastard",
      "Heart on the Staves",
      "Just a Little Bit",
      "Don't Stop (Remix)",
      "Fools Gold (The Top Won Mix)",
      "Fools Gold (Extended Remix)",
      'Waterfall (12" Version)',
      "I Am the Resurrection (Remix)",
      "Made of Stone (Remix)",
      'One Love (12" Version)',
      'Elephant Stone (12" Version)',
    ],
  },
  {
    name: "R. Kelly",
    songs: [
      "I Believe I Can Fly",
      "Ignition (Remix)",
      "Bump n' Grind",
      "Step in the Name of Love",
      "If I Could Turn Back the Hands of Time",
      "The World's Greatest",
      "Trapped in the Closet",
      "I'm a Flirt",
      "When a Woman's Fed Up",
      "Gotham City",
      "Same Girl",
      "Your Body's Callin'",
      "Happy People",
      "Feelin' on Yo Booty",
      "You Remind Me of Something",
      "Down Low (Nobody Has to Know)",
      "Fiesta",
      "I Wish",
      "Half on a Baby",
      "Slow Wind",
      "The Storm Is Over Now",
      "Burn It Up",
      "Snake",
      "Step in My Room",
      "I'm Your Angel",
      "Bad Man",
      "I Can't Sleep Baby (If I)",
      "When a Woman Loves",
      "Honey Love",
      "Home Alone",
      "She's Got That Vibe",
      "Religious Love",
      "Sex Me",
      "Be Careful",
      "Did You Ever Think",
      "Thoia Thoing",
      "Number One",
      "Radio Message",
      "U Saved Me",
      "Keep It on the Down Low",
      "Happy People/U Saved Me",
      "The Greatest Sex",
      "Forever More",
      "Only the Loot Can Make Me Happy",
      "Love Signals",
      "I'm a Flirt (Remix)",
      "I Can't Sleep Baby (If I) (Remix)",
      "Bump n' Grind (Remix)",
      "Feelin' on Yo Booty (Remix)",
      "Gotham City (Remix)",
    ],
  },
  {
    name: "The Cure",
    songs: [
      "Just Like Heaven",
      "Lovesong",
      "Friday I'm in Love",
      "Pictures of You",
      "Boys Don't Cry",
      "A Forest",
      "Close to Me",
      "Lullaby",
      "In Between Days",
      "Fascination Street",
      "The Lovecats",
      "Let's Go to Bed",
      "High",
      "Charlotte Sometimes",
      "Why Can't I Be You?",
      "The Walk",
      "Catch",
      "Primary",
      "A Night Like This",
      "Push",
      "Play for Today",
      "A Letter to Elise",
      "The Caterpillar",
      "Killing an Arab",
      "Jumping Someone Else's Train",
      "Three Imaginary Boys",
      "From the Edge of the Deep Green Sea",
      "Hot Hot Hot!!!",
      "Doing the Unstuck",
      "Cut Here",
      "Mint Car",
      "Other Voices",
      "The Hanging Garden",
      "Burn",
      "Dressing Up",
      "Six Different Ways",
      "The Blood",
      "A Strange Day",
      "Grinding Halt",
      "10:15 Saturday Night",
    ],
  },
  {
    name: "Culture Club",
    songs: [
      "Karma Chameleon",
      "Do You Really Want to Hurt Me",
      "Time (Clock of the Heart)",
      "I'll Tumble 4 Ya",
      "Church of the Poison Mind",
      "Miss Me Blind",
      "The War Song",
      "Victims",
      "It's a Miracle",
      "Move Away",
      "I Just Wanna Be Loved",
      "Love Is Love",
      "God Thank You Woman",
      "Black Money",
      "The Medal Song",
      "Mistake No. 3",
      "White Boy",
      "The Dive",
      "That's the Way (I'm Only Trying to Help You)",
      "Changing Every Day",
      "Stormkeeper",
      "Gusto Blusto",
      "Crime Time",
      "Man-Shake",
      "Dangerous Man",
      "Boy Boy (I'm the Boy)",
      "Reasons",
      "Starman",
      "I Pray",
      "Colour by Numbers",
      "You Know I'm Not Crazy",
      "Don't Talk About It",
      "Someone Else",
      "Come Clean",
      "Truth Behind Her Smile",
      "Sexuality",
      "Hurt",
      "Less Than Perfect",
      "Heaven's Children",
      "Runaway Train",
      "Do You Really Want to Hurt Me (Red Man Remix)",
      "Victims (Instrumental)",
      "Karma Chameleon (Remix)",
      "Mystery Boy",
      "I'm Afraid of Me",
      "White Boy (Dance Mix)",
      "Do You Really Want to Hurt Me (Dub Version)",
      "I'll Tumble 4 Ya (US Remix)",
      'Move Away (12" Mix)',
      "God Thank You Woman (Extended Version)",
    ],
  },
];
